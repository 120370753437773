import React, { useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'react-use';
import { Button, Input, InputNumber, Select, Form, Tooltip } from 'antd';
import { MEDIA } from '../../../../../utils/constants';
import { formatFloat, formatPrice } from '../../../../../utils/commons';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
// core
// utils
// locales
// store
import { useAppStore } from 'store';
import { useQueryFetchExteriorFilter } from '../../../../../hooks/api';
// types
import { IPropsType } from './types';
import {
  EInputSizeType,
  ESelectSizeType,
  EInputNumberSizeType,
} from 'types/units';
import { EGameType } from 'types/models';
// components(atoms)
import { ArrowDownIcon, MinusIcon, PlusIcon } from 'components/icons';
import { StickersBox } from 'components/features';
// styles
import '../index.scss';

// data

// func

const NewTradeModalCard = ({
  data,
  className,
  onPriceChange,
  onQualityChange,
  onCountChange,
}: IPropsType) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { gameType } = useAppStore();

  const [sortQualityOptions, setSortQualityOptions] = useState<any>([]);
  const { data: exteriorFilter, isLoading: isLoadingExteriorFilter } =
    useQueryFetchExteriorFilter(gameType === EGameType.CSGO);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!isLoadingExteriorFilter && exteriorFilter) {
      setSortQualityOptions(exteriorFilter?.possibilityAttributeValues);
      let { exterior, wears } = data;
      let quality = exterior ? exterior : wears?.length ? wears[0].name : null;
      if (quality) {
        const productExterior = exteriorFilter?.possibilityAttributeValues.find(
          el => el.title === quality,
        );
        form.setFieldValue('quality', {
          title: productExterior.title,
          value: productExterior.value,
        });
        onQualityChange({
          title: productExterior.title,
          value: productExterior.value,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exteriorFilter, isLoadingExteriorFilter]);

  const controlsSchema = yup.object().shape({
    price: yup
      .number()
      .test(
        'maxDigitsAfterDecimal',
        t('validations.amount'),
        // @ts-ignore
        number => /^\d+(\.\d{1,2})?$/.test(number),
      )
      .required(t('validations.required'))
      .min(0.03, t('validations.minValue', { min: 0.03 }))
      .max(99999, t('validations.maxValue', { max: 99999 }))
      .typeError(t('validations.isNumeric')),
    quality: yup.string().required(t('validations.required')),
    count: yup
      .number()
      .required(t('validations.required'))
      .positive(t('validations.numberPositive'))
      .typeError(t('validations.isNumeric')),
  });

  const yupSync = {
    async validator({ field }, value) {
      await controlsSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const Stickers = useMemo(() => {
    let collection =
      gameType === EGameType.CSGO
        ? [...data.stickers, ...data.keychains]
        : data.gems;
    if (!collection.length) return null;
    return (
      <StickersBox
        collection={collection}
        sku={data.sku}
        gameType={gameType}
        containerClassName={'trade-modal-card__stickers'}
        itemClassName={'trade-modal-card__sticker'}
      />
    );
  }, [data, gameType]);

  const Prices = useMemo(() => {
    let { minMarketPrice, minAutoBuyPrice } = data;
    return (
      <div className="trade-modal-card__prices">
        <div className="trade-modal-card__price">
          {t('minPrice')}
          <span>
            {minMarketPrice ? `$${formatPrice(minMarketPrice)}` : '$0.01'}
          </span>
        </div>
        <div className="trade-modal-card__price">
          {t('autobuy')}
          <span>
            {minAutoBuyPrice ? `$${formatPrice(minAutoBuyPrice)}` : '---'}
          </span>
        </div>
      </div>
    );
  }, [data, t]);

  const onIncrease = () => {
    let newCount = Number(form.getFieldValue('count')) + 1;
    form.setFieldValue('count', newCount);
    onCountChange(newCount);
  };

  const onDecrease = () => {
    let newCount = Number(form.getFieldValue('count')) - 1;
    form.setFieldValue('count', newCount);
    onCountChange(newCount);
  };

  const onChangeCount = value => {
    onCountChange(value);
  };

  return (
    <div
      className={
        'trade-modal-card trade-modal-card--extended' +
        (className ? ` ${className}` : '')
      }
    >
      <div className="trade-modal-card__left">
        <div className="trade-modal-card__img">
          <img
            src={data.preview?.length ? data.preview : data.image}
            alt={data.name}
          />
        </div>
        <div className="trade-modal-card__info">
          <div className="trade-modal-card__title">{data.marketName.first}</div>
          <div className="trade-modal-card__label">
            {data.marketName.second}
          </div>
          {gameType === EGameType.CSGO ? (
            <>{Stickers}</>
          ) : (
            <>
              <div className="trade-modal-card__label">{data.slot}</div>
              {Stickers}
            </>
          )}
          {width > MEDIA.M768 && width <= MEDIA.M1280 && Prices}
        </div>
      </div>
      <div className="trade-modal-card__right">
        {(width <= MEDIA.M768 || width > MEDIA.M1280) && Prices}
        <div className="trade-modal-card__divider" />
        <Form
          form={form}
          layout="vertical"
          className="trade-modal-card__form"
          initialValues={{
            price: '',
            quality: {
              title: 'Factory New',
              value: 'WearCategory0',
            },
            count: 1,
          }}
        >
          <div className="trade-modal-card__input">
            <label>{t('price')}</label>
            <Form.Item
              className="ant-form-item--flat"
              name={'price'}
              /*@ts-ignore*/
              rules={[yupSync]}
            >
              <Input
                placeholder={t('price')}
                size={EInputSizeType.Middle}
                prefix={'$'}
                onChange={({ target: { value } }) => {
                  let numberValue = Number(value) ? Number(value) : 0;
                  onPriceChange(numberValue);
                }}
              />
            </Form.Item>

            <div className="trade-modal-card__input-note">
              {t('minPrice')}: $0.01
            </div>
          </div>
          {data.hasFloat && (
            <div className="trade-modal-card__input">
              <label>{t('labels.quality')}</label>
              <Form.Item
                className="ant-form-item--flat"
                name={'quality'}
                /*@ts-ignore*/
                rules={[yupSync]}
              >
                <Select
                  className="ant-select--light"
                  size={ESelectSizeType.Middle}
                  suffixIcon={<ArrowDownIcon />}
                  onChange={event => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    onQualityChange(
                      sortQualityOptions.find(el => el.value === event),
                    );
                  }}
                  dropdownAlign={{ offset: [0, 0] }}
                  options={sortQualityOptions.map(el => ({
                    label: el.title,
                    value: el.value,
                  }))}
                  // @ts-ignore
                  getPopupContainer={() =>
                    document.querySelector('.trade-modal-card__form')
                  }
                />
              </Form.Item>

              <div className="trade-modal-card__input-note">
                {t('autobuyMinimal') + ': BS'}
              </div>
            </div>
          )}
          <div className="trade-modal-card__additional">
            <div className="trade-modal-card__input trade-modal-card__input--small">
              <label>{t('autobuyCount')}</label>
              <div className="trade-modal-card__counter">
                <Button
                  ghost
                  className="ant-btn-ghost--grey trade-modal-card__btn-count"
                  icon={<MinusIcon />}
                  disabled={Number(form.getFieldValue('count')) === 1}
                  onClick={() => onDecrease()}
                />
                <Form.Item
                  className="ant-form-item--flat"
                  name={'count'}
                  /*@ts-ignore*/
                  rules={[yupSync]}
                >
                  <InputNumber
                    controls={false}
                    className="trade-modal-card__input-count"
                    size={EInputNumberSizeType.Middle}
                    min={1}
                    max={100}
                    onChange={onChangeCount}
                  />
                </Form.Item>
                <Button
                  ghost
                  className="ant-btn-ghost--grey trade-modal-card__btn-count"
                  icon={<PlusIcon />}
                  disabled={Number(form.getFieldValue('count')) >= 40}
                  onClick={() => onIncrease()}
                />
              </div>
              <div className="trade-modal-card__input-note">
                {t('autobuyMinimum') + ': 1' + t('autobuyQuantity')}
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export { NewTradeModalCard };
