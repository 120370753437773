import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, Button } from 'antd';
// utils

// store
import { useAppStore } from 'store';

// types
import { EAlertType, EButtonSizeType, EButtonTypeType } from 'types/units';

// components

// styles
import './index.scss';

// data
/*@ts-ignore*/
import Maskot from '../../../../assets/img/maskot.png';

const HackedAttentionModal = ({ modalId, data, onClose }) => {
  const { sModalClose } = useAppStore();
  const onConfirm = id => {
    sModalClose(id);
    onClose(data.id);
  };
  return (
    <>
      {data.type === EAlertType.Error ? (
        <div className="hacked-attention-modal">
          <div className="hacked-attention-modal__head">
            <img src={Maskot} alt="Skinsty" />
          </div>
          <div className="hacked-attention-modal__body">
            <div className="hacked-attention-modal__title">{data.title}</div>
            <Typography
              className="hacked-attention-modal__text"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
          </div>
          <div className="hacked-attention-modal__actions">
            <Button
              block
              type={EButtonTypeType.Primary}
              size={EButtonSizeType.Large}
              onClick={() => {
                onConfirm(modalId);
              }}
            >
              Хорошо
            </Button>
            <Typography.Text className="hacked-attention-modal__actions-link">
              <NavLink to={'support/faq'}>Связаться с тех. поддержкой</NavLink>
            </Typography.Text>
          </div>
        </div>
      ) : (
        <div className="withdraw-balance-confirmation block-warning-modal">
          <div className="withdraw-balance-confirmation__head withdraw-balance-confirmation__head--small">
            <div className="withdraw-balance-confirmation__head--bg">
              <img src={Maskot} alt="Skinsty" />
            </div>
          </div>
          <div className="withdraw-balance-confirmation__desc">
            <div className="withdraw-balance-confirmation__desc--title">
              <span>{data.title}</span>
            </div>
            <div
              className="withdraw-balance-confirmation__desc--content"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
          </div>
          <div className="withdraw-balance-confirmation__actions withdraw-balance-confirmation__actions--light">
            <Button
              block
              type={EButtonTypeType.Primary}
              size={EButtonSizeType.Large}
              onClick={() => {
                onConfirm(modalId);
              }}
            >
              Понятно
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export { HackedAttentionModal };
