export const faqFilters: Array<{
  title: string;
  value: string | undefined;
}> = [
  {
    title: 'filters.faqAll',
    value: undefined,
  },
  {
    title: 'filters.faqSell',
    value: 'sell',
  },
  {
    title: 'filters.faqBuy',
    value: 'buy',
  },
  {
    title: 'filters.faqOther',
    value: 'other',
  },
];
