import { useEffect, useRef, createContext, useContext, useState } from 'react';
import io from 'socket.io-client';

const SOCKET_SERVER_URL = `${process.env.REACT_APP_SOCKET_URL}`; // Replace with your backend server URL

export const SocketContext = createContext({ socket: null });

export const useSocketSubscribe = (eventName, eventHandler) => {
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    // console.log('SocketIO: adding listener', eventName);
    // @ts-ignore
    socket.on(eventName, eventHandler);

    return () => {
      // console.log('SocketIO: removing listener', eventName);
      // @ts-ignore
      socket?.off(eventName, eventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventHandler]);

  // @ts-ignore
  return { isConnected: socket?.connected };
};

export const useSocketDispatch = () => {
  const { socket } = useContext(SocketContext);

  const socketEmit = (type: string, data: any) => {
    if (socket) {
      // @ts-ignore
      socket.emit(type, data);
    }
  };
  // @ts-ignore
  return { isConnected: socket?.connected, socketEmit };
};

const SocketProvider = ({ children }) => {
  const socket = useRef(
    io(SOCKET_SERVER_URL, {
      transports: ['websocket'],
    }),
  );

  useEffect(() => {
    socket.current.on('connect', () => {
      console.log('SocketIO: Connected and authenticated');
    });

    socket.current.on('error', (msg: string) => {
      console.error('SocketIO: Error', msg);
    });

    return () => {
      if (socket && socket.current) {
        socket.current.removeAllListeners();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        socket.current.close();
      }
    };
  }, []);

  return (
    // @ts-ignore
    <SocketContext.Provider value={{ socket: socket.current }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
