import React, { useEffect, useState } from 'react';
import { Input } from 'antd';

// locale

// types
import { IPropsType } from './types';
import { EInputSizeType } from 'types/units';

// components
import { FloatRange } from 'components/atoms';

// styles
import './index.scss';
import { IFloatRangeItemType } from '../../../atoms/FloatRange/types';

//data

const FloatFilter = ({ data, onChange }: IPropsType) => {
  const [floatRange, setFloatRange] = useState(data ? data : ['0', '1']);

  useEffect(() => {
    setFloatRange(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const onChangeFloat = (event, index) => {
    let value = event.target.value;

    if (
      !isNaN(Number(value)) &&
      Number(value) >= 0 &&
      Number(value) <= 1 &&
      value.length <= 4
    ) {
      let float = [...floatRange];

      float[index] = event.target.value;
      setFloatRange(float);
      onChange(float);
    }
  };

  const onClickFloatRange = (data: IFloatRangeItemType) => {
    let { minValue, maxValue } = data;
    let float = [minValue.toString(), maxValue.toString()];
    setFloatRange(float);
    onChange(float);
  };

  return (
    <div className="float-filter">
      <FloatRange
        isTooltip={true}
        minValue={floatRange[0] ? Number(floatRange[0]) : 0}
        maxValue={floatRange[1] ? Number(floatRange[1]) : 1}
        isCanSelect={true}
        onClickRange={onClickFloatRange}
      />
      <div className="float-filter__grid">
        <Input
          className="ant-input-simpler-wrapper"
          prefix={' '}
          value={floatRange[0]}
          size={EInputSizeType.Small}
          onChange={event => onChangeFloat(event, 0)}
        />
        <div className="float-filter__divider"></div>
        <Input
          className="ant-input-simpler-wrapper"
          prefix={' '}
          value={floatRange[1]}
          size={EInputSizeType.Small}
          onChange={event => onChangeFloat(event, 1)}
        />
      </div>
    </div>
  );
};
export { FloatFilter };
