import { floatRanges } from './data';

export enum EFloatRangeSizeType {
  Default = 'default',
  Small = 'small',
}

export interface IFloatRangeItemType {
  label: string;
  minValue: number;
  maxValue: number;
  minTitle: string;
  maxTitle: string;
}

export interface IPropsType {
  minValue?: number;
  maxValue?: number;
  size?: EFloatRangeSizeType;
  isTooltip?: boolean;
  isTooltipValue?: boolean;
  isCanSelect?: boolean;
  onClickRange?: (data: IFloatRangeItemType) => void;
}
