import React, { useMemo } from 'react';
import { useWindowSize } from 'react-use';
// core
// utils
// locales
// store
// types
import { IPropsType } from './types';
import { EGameType } from 'types/models/game';
// components(atoms)

// styles
import './index.scss';
import { Tooltip } from 'antd';
import { StickerTooltip } from 'components/features';
import { MEDIA } from '../../../../utils/constants';

// data

// func

const TableCellStickers = ({ collection, gameType }: IPropsType) => {
  const { width } = useWindowSize();

  const Stickers = useMemo(() => {
    if (!collection.length) return null;

    const paddingWidth = width > MEDIA.M1280 ? 120 : 32;

    return collection.map((item, index) => (
      <Tooltip
        key={index}
        overlayStyle={{ maxWidth: width - paddingWidth - 16 }}
        placement="top"
        title={
          gameType === EGameType.CSGO ? (
            <StickerTooltip title={item.title} price={item.valueExtra} />
          ) : (
            <StickerTooltip title={item.title} description={item.valueExtra} />
          )
        }
      >
        <img src={item.preview} alt={item.title} />
      </Tooltip>
    ));
  }, [collection, gameType, width]);

  return (
    <div
      className={
        'table-cell-stickers' +
        (gameType === EGameType.CSGO
          ? ' table-cell-stickers--csgo'
          : ' table-cell-stickers--dota')
      }
    >
      {Stickers}
    </div>
  );
};

TableCellStickers.defaultProps = {
  gameType: EGameType.CSGO,
} as { gameType: EGameType };

export { TableCellStickers };
