export const selectItems = [
  {
    label: 'filters.blogByViews',
    value: 'views',
  },
  {
    label: 'filters.blogByDate',
    value: 'published_at',
  },
];
