export enum EInventoryAsideType {
  Empty = 'empty',
  Product = 'product',
  Expose = 'expose',
}

export enum EInventoryModalSellType {
  ProductCard = 'product_sell',
  InventoryProduct = 'inventory_product',
  InventoryExpose = 'inventory_expose',
}
