import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// utils

// locales
import { useTranslation } from 'react-i18next';

// store

// types
// components(atoms)
import {
  DuckyIcon,
  PersonIcon,
  SteamIcon,
  LockIcon,
  WindowIcon,
  BagIcon,
} from 'components/icons';

// styles

// data

// func

const DashboardNavigation = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <div className="dashboard__nav--links-item__wrap">
      <NavLink
        to={`/dashboard/referral`}
        className={
          'dashboard__nav--links-item' +
          (pathname === '/dashboard/referral' ? ' active' : '')
        }
      >
        <DuckyIcon />
        <span>{t('pages.dashboard.referrals.title')}</span>
      </NavLink>
      <NavLink
        to={`/dashboard/profile`}
        className={
          'dashboard__nav--links-item' +
          (pathname === '/dashboard/profile' ? ' active' : '')
        }
      >
        <PersonIcon />
        <span>{t('profile')}</span>
      </NavLink>
      <NavLink
        to={`/dashboard/steam-account`}
        className={
          'dashboard__nav--links-item' +
          (pathname === '/dashboard/steam-account' ? ' active' : '')
        }
      >
        <SteamIcon />
        <span>{t('steamAccount')}</span>
      </NavLink>
      <NavLink
        to={`/dashboard/security-and-payments`}
        className={
          'dashboard__nav--links-item' +
          (pathname === '/dashboard/security-and-payments' ? ' active' : '')
        }
      >
        <LockIcon />
        <span>{t('securityAndPayments')}</span>
      </NavLink>
      <NavLink
        to={`/dashboard/site-settings`}
        className={
          'dashboard__nav--links-item' +
          (pathname === '/dashboard/site-settings' ? ' active' : '')
        }
      >
        <WindowIcon />
        <span>{t('siteSettings')}</span>
      </NavLink>
      <NavLink
        to={`/dashboard/language-and-currency`}
        className={
          'dashboard__nav--links-item' +
          (pathname === '/dashboard/language-and-currency' ? ' active' : '')
        }
      >
        <BagIcon />
        <span>{t('languageAndCurrency')}</span>
      </NavLink>
    </div>
  );
};
export { DashboardNavigation };
