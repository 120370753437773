import i18next from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { languagesList, DEFAULT_LOCALE } from './data';

// front locales
import en from './en/translation.json';
import ru from './ru/translation.json';

import {
  convertLanguageJsonToObject,
  generateRandomHash,
} from './translations';

export const translationsJson = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
};

const HASH = generateRandomHash();

const getLocaleKey = () => {
  let storageKey = localStorage.getItem('i18nextLng');
  return storageKey ? storageKey : DEFAULT_LOCALE;
};

// Create the 'translations' object to provide full intellisense support for the static json files.
// convertLanguageJsonToObject(en);

// @ts-ignore
export const i18n = i18next
  .use(ChainedBackend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: {
      // order and from where user language should be detected
      order: ['localStorage', 'navigator', 'htmlTag'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
      htmlTag: document.documentElement,
    },
    // resources: translationsJson, // or use uncomment next lines for back instead this
    backend: {
      backends: [LocalStorageBackend, HttpBackend],

      backendOptions: [
        {
          expirationTime: 24 * 60 * 60 * 1000, // 1 days
          defaultVersion: `v0.0.2`,
          // defaultVersion: `v${HASH}`,
        },
        {
          loadPath: `${process.env.REACT_APP_API_URL}/config/translations`,
          customHeaders: {
            Accept: 'application/json',
            'X-localization': getLocaleKey(),
          },
          // @ts-ignore
          parse: function (data: string, languages: string) {
            let localTranslation = translationsJson[languages].translation;
            let dataTranslation = JSON.parse(data);
            return localTranslation
              ? Object.assign({}, localTranslation, dataTranslation)
              : dataTranslation;
          },
        },
      ],
    },
    react: {
      useSuspense: true,
    },
    supportedLngs: languagesList.map(lang => lang.id),
    load: 'languageOnly',
    // lng: getLocaleKey(),
    fallbackLng: DEFAULT_LOCALE,
    // debug: process.env.NODE_ENV !== 'production',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
