import React from 'react';
import { Button } from 'antd';
// core

// utils
import { formatPrice } from '../../../../utils/commons';
// locales
import { useTranslation } from 'react-i18next';
//store

// types
import { IPropsType } from './types';

// components(atoms)
// styles
import './index.scss';

// data

// func

const StickerTooltip = ({
  className,
  title,
  price,
  description,
  small,
}: IPropsType) => {
  const { t } = useTranslation();
  return (
    <div
      className={
        'sticker-tooltip' +
        (className ? className : '') +
        (small ? ' sticker-tooltip--small' : '')
      }
    >
      <div className="sticker-tooltip__title">{title}</div>
      {price && (
        <div className="sticker-tooltip__grid">
          {t('price')}
          <span>$ {formatPrice(Number(price))}</span>
        </div>
      )}
      {description && (
        <div className="sticker-tooltip__grid">
          <span>{description}</span>
        </div>
      )}
    </div>
  );
};

export { StickerTooltip };
