import React from 'react';
// utils

// locales

// store

// types
import { IPropsType } from './types';

// components(atoms)

// styles
import './index.scss';

// data

// func
const DashboardBlock = ({
  children,
  title,
  description,
  gradient = false,
  isColumns,
}: IPropsType) => {
  return (
    <div
      className={
        'dashboard-block ' +
        (gradient ? 'dashboard-block--gradient' : '') +
        (isColumns ? 'dashboard-block--columns' : '')
      }
    >
      <div className="dashboard-block__left">
        <div className="dashboard-block__title">{title}</div>
        {description && (
          <div className="dashboard-block__desc">{description}</div>
        )}
      </div>
      <div className="dashboard-block__right">{children}</div>
    </div>
  );
};
export { DashboardBlock };
