import { queryStringToObject } from 'utils/commons';
import {
  EGameType,
  EMarketFilterCategoryType,
  EMarketFilterType,
  EMarketFilterUrlType,
  ESortType,
  ESortValueType,
  ISortOption,
} from 'types/models';

export const sortOptions: Array<ISortOption> = [
  {
    label: 'filters.featured',
    value: 'recommended',
    type: null,
    typeValue: null,
  },
  {
    label: 'filters.dateDesc',
    value: `${ESortType.createdAt}_${ESortValueType.DESC}`,
    type: ESortType.createdAt,
    typeValue: ESortValueType.DESC,
  },
  {
    label: 'filters.dateAsc',
    value: `${ESortType.createdAt}_${ESortValueType.ASC}`,
    type: ESortType.createdAt,
    typeValue: ESortValueType.ASC,
  },
  {
    label: 'filters.priceDesc',
    value: `${ESortType.price}_${ESortValueType.DESC}`,
    type: ESortType.price,
    typeValue: ESortValueType.DESC,
  },
  {
    label: 'filters.priceAsc',
    value: `${ESortType.price}_${ESortValueType.ASC}`,
    type: ESortType.price,
    typeValue: ESortValueType.ASC,
  },
  {
    label: 'filters.floatDesc',
    value: `${ESortType.float}_${ESortValueType.DESC}`,
    type: ESortType.float,
    typeValue: ESortValueType.DESC,
  },
  {
    label: 'filters.floatAsc',
    value: `${ESortType.float}_${ESortValueType.ASC}`,
    type: ESortType.float,
    typeValue: ESortValueType.ASC,
  },
  // {
  //   label: 'filters.qualityDesc',
  //   value: `${ESortType.quality}_${ESortValueType.DESC}`,
  //   type: ESortType.quality,
  //   typeValue: ESortValueType.DESC,
  // },
  // {
  //   label: 'filters.qualityAsc',
  //   value: `${ESortType.quality}_${ESortValueType.ASC}`,
  //   type: ESortType.quality,
  //   typeValue: ESortValueType.ASC,
  // },
];
export const getInitSortAttributes = query => {
  let queryObject = queryStringToObject(query);
  let sortAttributes: undefined | ISortOption = undefined;

  if (queryObject.Sort) {
    sortAttributes = sortOptions.find(item => {
      return item.value === queryObject.Sort;
    });
  }
  return sortAttributes ? sortAttributes : sortOptions[3];
};

const createCategoryObject = (categoryObject, group, isHideAll = false) => {
  categoryObject.possibilityGroups = group.filterAttributes.reduce(
    (accGroupItem, groupItem) => {
      let { attribute, possibilityAttributeValues } = groupItem;
      return [
        ...accGroupItem,
        {
          sku: attribute.sku.trim(),
          title: attribute.title.trim(),
          icon: attribute.image ? attribute.image : attribute.preview,
          possibilityValues: isHideAll
            ? [...possibilityAttributeValues]
            : [
                ...possibilityAttributeValues,
                {
                  image: null,
                  title: 'selectAll',
                  value: `${EMarketFilterCategoryType.All}_${attribute.sku}`,
                  valueExtra: possibilityAttributeValues.map(i => i.value),
                },
              ],
        },
      ];
    },
    [],
  );

  return categoryObject;
};

export const getIniApiAttributes = (filtersData, query, gameType) => {
  let queryObject = queryStringToObject(query);

  if (
    typeof queryObject.GameType === 'string' &&
    queryObject.GameType !== gameType
  ) {
    queryObject = {
      GameType: gameType,
    };
  }

  let categoriesObject: any = {};

  let searchCollection = '';

  //---create structure
  filtersData.forEach(group => {
    if (group.type === EMarketFilterType.Top) {
      if (gameType === EGameType.CSGO) {
        categoriesObject = {
          ...categoriesObject,
          Weapon: createCategoryObject(
            {
              sku: 'Weapon',
              title: 'Weapon',
              urlType: EMarketFilterUrlType.Radio,
              type: EMarketFilterType.Top,
              dataValues: {},
              possibilityGroups: [],
            },
            group,
          ),
        };
      } else {
        categoriesObject = {
          ...categoriesObject,
          CategoryDota: createCategoryObject(
            {
              sku: 'CategoryDota',
              title: 'CategoryDota',
              urlType: EMarketFilterUrlType.Multiselect,
              type: EMarketFilterType.Top,
              dataValues: {},
              possibilityGroups: [],
            },
            group,
          ),
        };
      }
    } else if (group.type === EMarketFilterType.Heroes) {
      //---dota

      categoriesObject = {
        ...categoriesObject,
        Heroes: createCategoryObject(
          {
            sku: 'Heroes',
            title: 'Heroes',
            urlType: EMarketFilterUrlType.Radio,
            type: EMarketFilterType.Top,
            dataValues: {},
            possibilityGroups: [],
          },
          group,
          true,
        ),
      };
    }
  });

  //---category
  let categoriesCollection = Object.keys(categoriesObject).reduce(
    (acc, key) => {
      let categoryItem = categoriesObject[key];

      let dataValues: object = {};

      if (categoryItem.urlType === EMarketFilterUrlType.Radio) {
        //---Radio
        let keyDataValue = key;
        let queryValueString = queryObject[keyDataValue];
        let dataValueGroup: { sku: any; values: any } = {
          sku: null,
          values: [],
        };

        if (queryValueString && typeof queryValueString === 'string') {
          let queryValueArray = queryValueString.split(',');

          queryValueArray.forEach(qvaItem => {
            categoryItem.possibilityGroups.forEach(pGroup => {
              let possibilityValue = pGroup.possibilityValues.find(
                pItem => pItem.value === qvaItem,
              );

              if (
                possibilityValue &&
                (!dataValueGroup.sku || dataValueGroup.sku === pGroup.sku)
              ) {
                dataValueGroup.sku = pGroup.sku;
                dataValueGroup.values = [
                  ...dataValueGroup.values,
                  possibilityValue.value,
                ];
              }
            });
          });
          dataValues = {
            ...dataValues,
            [keyDataValue]: dataValueGroup.values.join(','),
          };
        }
      } else {
        //---Mulitiselect
        categoryItem.possibilityGroups.forEach(pGroup => {
          let keyDataValue = pGroup.sku;
          let queryValueString = queryObject[keyDataValue];
          let dataValueGroup: { sku: any; values: any } = {
            sku: null,
            values: [],
          };

          if (queryValueString && typeof queryValueString === 'string') {
            let queryValueArray = queryValueString.split(',');

            queryValueArray.forEach(qvaItem => {
              let possibilityValue = pGroup.possibilityValues.find(
                pItem => pItem.value === qvaItem,
              );

              if (
                possibilityValue &&
                (!dataValueGroup.sku || dataValueGroup.sku === pGroup.sku)
              ) {
                dataValueGroup.sku = pGroup.sku;
                dataValueGroup.values = [
                  ...dataValueGroup.values,
                  possibilityValue.value,
                ];
              }
            });
            dataValues = {
              ...dataValues,
              [keyDataValue]: dataValueGroup.values.join(','),
            };
          }
        });
      }

      return { ...acc, [key]: { ...categoryItem, dataValues } };
    },
    {},
  );

  //---search
  let querySearchString = queryObject['NameSearch'];
  if (querySearchString && typeof querySearchString === 'string') {
    searchCollection = querySearchString;
  }

  return { categoriesCollection, searchCollection };
};

export const updateUrlParams = (
  categories,
  sort,
  search,
  gameType,
  page = '1',
) => {
  let sortParams = sort?.typeValue ? { Sort: [sort.value] } : {};

  let categoriesParams = Object.keys(categories).reduce((acc, key) => {
    let { dataValues, urlType } = categories[key];
    if (urlType === EMarketFilterUrlType.Radio) {
      if (dataValues[key]) {
        acc = { ...acc, [key]: dataValues[key] };
      }
    } else if (urlType === EMarketFilterUrlType.Multiselect) {
      Object.keys(dataValues).forEach(keyDataValue => {
        acc = { ...acc, [keyDataValue]: dataValues[keyDataValue] };
      });
    }

    return acc;
  }, {} as any);

  let searchParams = search ? { NameSearch: search } : {};
  let pageParams = page && Number(page) > 1 ? { Page: page } : {};

  return {
    ...categoriesParams,
    ...sortParams,
    ...searchParams,
    ...pageParams,
    GameType: gameType,
  };
};
