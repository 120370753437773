export enum ESocketEventType {
  ExtensionConnect = 'extensionConnect',
  Balance = 'balance',
  UserBan = 'userBan',
  BuyMarketItem = 'buyMarketItem',
  InventoryFetchStart = 'inventoryFetchStart',
  InventoryFetch = 'inventoryFetch',
  InventoryFetchFinish = 'inventoryFetchFinish',
  Notification = 'notification',
  Message = 'message',
  Broadcast = 'broadcast',
}

export enum ESocketEmitType {
  Join = 'join',
  JoinChat = 'joinChat',
}
