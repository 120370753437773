import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { Avatar, Button, Form, Input, Select, Table, Tooltip } from 'antd';
import { Area } from '@ant-design/plots';

// utils
import {
  formatDate,
  formatPartsPrice,
  formatPrice,
} from '../../../../../utils/commons';
// locales
import { useTranslation } from 'react-i18next';

//hooks
import {
  useMutationSetReferral,
  useQueryFetchReferralChart,
  useQueryFetchReferralLink,
} from '../../../../../hooks/api';

// store
import { useAppStore } from 'store';

// types
import { EAvatarSizeType, EButtonSizeType, ESelectSizeType } from 'types/units';
import { ELoaderTypeType } from '../../../../../components/atoms/AppLoader/types';
import { ECardSizeType } from '../../../../../components/features/Cards/EmptyCard/types';

// components(atoms)
import { DashboardBlock, DashboardHead } from '../../components';
import {
  ArrowDownIcon,
  ArrowLeftLightIcon,
  ArrowRightIcon,
  DuckyIcon,
  LinkIcon,
  PersonIcon,
} from 'components/icons';
import { toast } from 'react-toastify';
import { AppLoader } from 'components/atoms';
import { EmptyCard } from 'components/features';

// styles
import './index.scss';

// data
import {
  generalChartConfig,
  generalTooltipConfig,
} from '../../../../../config/charts';

import { dataRangeValue, selectData } from './data';

const { Option } = Select;
// func
const DashboardReferral = () => {
  const { t } = useTranslation();
  const { gameType } = useAppStore();

  const [enabledEditReferral, setEnabledEditReferral] =
    useState<boolean>(false);
  const [currentRange, setCurrentRange] = useState<any>(
    dataRangeValue[0].value,
  );
  const [currentSelect, setCurrentSelect] = useState<string>(
    selectData[0].value,
  );

  const [referralsPayload, setReferralsPayload] = useState<any>({
    page: 1,
    take: 10,
    provider: gameType,
  });

  const createReferralSchema = yup.object().shape({
    code: yup
      .string()
      .required(t('validations.required'))
      .matches(/^[a-zA-Z0-9\-_]+$/u, t('validations.queryString')),
  });

  const yupSync = {
    async validator({ field }, value) {
      await createReferralSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const { data: referralChart, isLoading: isLoadingReferralChart } =
    useQueryFetchReferralChart(currentRange);

  const referralChartData = useMemo(() => {
    return referralChart && !isLoadingReferralChart ? referralChart : null;
  }, [referralChart, isLoadingReferralChart]);

  const totalEarned = useMemo(() => {
    return referralChart
      ? referralChart.reduce(
          // @ts-ignore
          (acc, currentValue) => acc + currentValue.scales,
          0,
        )
      : 0;
  }, [referralChart]);

  const { xAxis, yAxis, ...chartConfig } = generalChartConfig;

  const config = {
    data: referralChartData,
    xField: 'Date',
    yField: 'scales',
    ...chartConfig,
    xAxis: { ...xAxis, tickCount: 7 },
    yAxis: { ...yAxis, tickCount: 3, position: 'left' },
    tooltip: {
      ...generalTooltipConfig,
      customContent: (title, items) => {
        if (items && items.length) {
          const { data } = items[0];
          return (
            <div className="g2-tooltip__content">
              <div className="g2-tooltip__date">
                {dayjs(title).format('MMMM D, HH:mm')}
              </div>
              <div className="g2-tooltip__price">{data.scales}</div>
              <div className="g2-tooltip__count">
                {t('sold')} <span>{data.count}</span>
              </div>
            </div>
          );
        } else {
          return null;
        }
      },
    },
  };

  const [form] = Form.useForm();

  const {
    data: referralLinkData,
    isLoading: referralLinkLoading,
    refetch: refetchReferralLinkData,
  } = useQueryFetchReferralLink();

  const {
    data: setReferralData,
    isLoading: isSetReferralLoading,
    mutate: mutateSetReferral,
  } = useMutationSetReferral();

  useEffect(() => {
    if (referralLinkData && !referralLinkLoading) {
      form.setFieldsValue({ code: referralLinkData?.link });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralLinkLoading, referralLinkData]);

  useEffect(() => {
    if (setReferralData && !isSetReferralLoading) {
      setEnabledEditReferral(false);
      toast.success(t('toastUpdatedReferralLink'));
      refetchReferralLinkData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setReferralData, isSetReferralLoading]);

  const onChangeSelect = (value: string) => {
    setCurrentRange(dataRangeValue.find(el => el.id === value)?.value);
  };

  const onSaveReferralLink = () => {
    form.submit();
  };

  const onSetReferral = payload => {
    mutateSetReferral(payload);
  };

  const onCopyReferral = () => {
    let textarea = document.createElement('textarea');
    textarea.value =
      window.location.origin + '?referral=' + referralLinkData?.link;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  const onTableChange = pagination => {
    setReferralsPayload({ ...referralsPayload, page: pagination.current });
  };

  const dynamicColumns = useMemo(() => {
    return [
      {
        key: 'avatar',
        dataIndex: 'img',
        width: '40%',
        render: (_, record) => (
          <div className="dashboard-referral__table-customer">
            <Avatar
              className="dashboard-referral__table-avatar"
              size={EAvatarSizeType.Default}
              src={record.avatar}
            />

            <Tooltip
              overlayClassName={'ant-tooltip--small'}
              placement="top"
              title={
                <div className="dashboard-referral__table-tooltip">
                  {record.username}
                </div>
              }
            >
              <span className="dashboard-referral__table-username">
                {record.username}
              </span>
            </Tooltip>
          </div>
        ),
      },
      {
        key: 'purchases',
        dataIndex: 'purchases',
        render: purchases => (
          <div className="ant-table-cell__nowrap">
            {t('pages.dashboard.referrals.yourReferralsPurchases')}{' '}
            <span className="ant-table-cell__nowrap ant-table-cell__value">
              $ {formatPrice(purchases)}
            </span>
          </div>
        ),
      },
      {
        key: 'income',
        dataIndex: 'income',
        render: income => (
          <div className="ant-table-cell__nowrap">
            {t('pages.dashboard.referrals.yourReferralsIncome')}{' '}
            <span className="ant-table-cell__nowrap ant-table-cell__value">
              $ {formatPrice(income)}
            </span>
          </div>
        ),
      },
      {
        key: 'date',
        dataIndex: 'date',
        render: date => (
          <div className="ant-table-cell__nowrap">
            {t('labels.date')}{' '}
            <span className="ant-table-cell__value">
              {formatDate(date, 'DD.MM.YYYY')}
            </span>
          </div>
        ),
      },
    ];
  }, [t]);
  return (
    <div className="dashboard__main">
      <div className="dashboard__main--wrap">
        <DashboardHead title={t('pages.dashboard.referrals.title')}>
          <></>
        </DashboardHead>
        {referralLinkLoading ? (
          <div className="dashboard__main-loader">
            <AppLoader type={ELoaderTypeType.Component} />
          </div>
        ) : (
          <>
            <DashboardBlock
              gradient
              title={t('pages.dashboard.referrals.yourIncomeTitle')}
              description={t('pages.dashboard.referrals.yourIncomeDesc')}
            >
              <div className="dashboard-referral">
                <div className="dashboard-referral__left">
                  <div className="dashboard-referral__card">
                    <div className="dashboard-referral__card-head">
                      <div className="dashboard-referral__card-title">
                        {t('pages.dashboard.referrals.totalBuyings')}
                      </div>
                    </div>
                    <div className="dashboard-referral__card-body">
                      <div className="dashboard-referral__card-value">
                        <h3>
                          ${' '}
                          {
                            formatPartsPrice(referralLinkData?.income)
                              .significantDigit
                          }
                          <span>
                            .
                            {
                              formatPartsPrice(referralLinkData?.income)
                                .fractionDigit
                            }
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-referral__card">
                    <div className="dashboard-referral__card-head">
                      <div className="dashboard-referral__card-title">
                        {t('pages.dashboard.referrals.attracted')}
                      </div>
                    </div>
                    <div className="dashboard-referral__card-body">
                      <div className="dashboard-referral__card-value">
                        <h3>
                          <PersonIcon />
                          {referralLinkData?.referrals
                            ? referralLinkData.referrals.length
                            : 0}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard-referral__right">
                  <div className="dashboard-referral__card dashboard-referral__card--glow">
                    <div className="dashboard-referral__card-head">
                      <div className="dashboard-referral__card-title">
                        {t('pages.dashboard.referrals.totalEarnings')}
                      </div>
                      <div className="dashboard-referral__card-action">
                        <Select
                          className="ant-select--rounded"
                          size={ESelectSizeType.Small}
                          suffixIcon={<ArrowDownIcon />}
                          defaultValue={currentSelect}
                          onChange={onChangeSelect}
                          dropdownAlign={{ offset: [0, 0] }}
                        >
                          {selectData?.length &&
                            selectData.map((el, index) => {
                              return (
                                <Option
                                  label={t(el.label)}
                                  value={el.value}
                                  key={index}
                                >
                                  {t(el.label)}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                    <div className="dashboard-referral__card-body">
                      <div className="dashboard-referral__card-value">
                        <h3>
                          $ {formatPartsPrice(totalEarned).significantDigit}
                          <span>
                            .{formatPartsPrice(totalEarned).fractionDigit}
                          </span>
                        </h3>
                      </div>
                      <div className="dashboard-referral__card-income">
                        {/*+9.55 (2%) {t('inWeek')}*/}
                      </div>
                      <div className="dashboard-referral__card-chart">
                        {/*@ts-ignore*/}
                        <Area {...config} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DashboardBlock>
            <DashboardBlock
              title={t('pages.dashboard.referrals.refferalLinkTitle')}
              description={t('pages.dashboard.referrals.refferalLinkDesc')}
              gradient={true}
            >
              <Form onFinish={onSetReferral} form={form} layout="vertical">
                <div className="dashboard__main-form">
                  <div className="dashboard__main-form-input dashboard__main-form-input--referral">
                    <Form.Item
                      name={'code'}
                      /*@ts-ignore*/
                      rules={[yupSync]}
                    >
                      <Input
                        readOnly={!enabledEditReferral}
                        className="ant-input-group-wrapper--custom-addon"
                        addonBefore={
                          <>
                            <DuckyIcon />
                            <span>{t('referalLink')}</span>
                          </>
                        }
                        addonAfter={
                          <>
                            {!enabledEditReferral ? (
                              <Button
                                size={EButtonSizeType.Middle}
                                className="ant-btn-text--primary"
                                type="text"
                                onClick={() => {
                                  setEnabledEditReferral(true);
                                }}
                              >
                                {t('change')}
                              </Button>
                            ) : (
                              <Button
                                size={EButtonSizeType.Middle}
                                className="ant-btn-text--primary"
                                type="text"
                                onClick={() => {
                                  onSaveReferralLink();
                                }}
                              >
                                {t('save')}
                              </Button>
                            )}

                            <Button
                              size={EButtonSizeType.Middle}
                              className="ant-btn-text--primary"
                              type="text"
                              onClick={() => {
                                onCopyReferral();
                              }}
                            >
                              {t('get')} <LinkIcon />
                            </Button>
                          </>
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </DashboardBlock>
            <DashboardBlock
              title={t('pages.dashboard.referrals.yourReferralsTitle')}
              description={t('pages.dashboard.referrals.yourReferralsDesc')}
              isColumns={true}
            >
              <Table
                showHeader={false}
                onChange={pagination => {
                  onTableChange(pagination);
                }}
                pagination={{
                  position: ['bottomCenter'],
                  defaultCurrent: 1,
                  defaultPageSize: 5,
                  total: referralLinkData?.referrals.length,
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                  itemRender: (page, type, originalElement) => {
                    if (type === 'next') {
                      return <ArrowRightIcon />;
                    } else if (type === 'prev') {
                      return <ArrowLeftLightIcon />;
                    }
                    return originalElement;
                  },
                }}
                className="dashboard-referral-table"
                columns={dynamicColumns}
                dataSource={referralLinkData?.referrals}
                rowKey="id"
                scroll={{ x: '780px' }}
                locale={{
                  emptyText: (
                    <div className="ant-table-cell__table-empty-small">
                      <EmptyCard
                        size={ECardSizeType.Small}
                        title={t('emptySimilarTitle')}
                        description={t('emptySimilarDesc')}
                      />
                    </div>
                  ),
                }}
              />
            </DashboardBlock>
          </>
        )}
      </div>
    </div>
  );
};
export { DashboardReferral };
