import React from 'react';
import { Alert } from 'antd';

//hooks
import { useMutationReadNotification } from '../../../../hooks/api';
// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore, useAuthStore } from 'store';

// types
import { EAlertType } from 'types/units';
import { IPropsType } from './types';

// components
import { CloseIcon } from 'components/icons';
import { HeaderAlertTimer } from './HeaderAlertTimer';

// styles
import './index.scss';

//data

const HeaderAlert = ({
  alertType,
  blockTime,
  data,
  isBanned,
  onOpen,
}: IPropsType) => {
  const { t } = useTranslation();
  const { sModalOpen } = useAppStore();
  // const { accessToken: isAuth } = useAuthStore();
  const { accessToken: isAuth } = useAuthStore();
  const { mutate: mutateReadNotification } = useMutationReadNotification();

  const renderText = (type, t) => {
    if (type === EAlertType.Warning && isBanned) {
      return (
        <>
          {t('temporaryBlocked')}: {<HeaderAlertTimer targetDate={blockTime} />}
          . {t('clickForMoreBanInfo')}
        </>
      );
    } else {
      return <>{data.title}</>;
    }
  };

  const onOpenModal = type => {
    if (onOpen && data) {
      onOpen(data);
    }
    if (type === EAlertType.Warning && isBanned) {
      sModalOpen('blockWarning');
    } else {
      sModalOpen('hackedAttention');
    }
  };

  const onCloseAlert = () => {
    if (isAuth) {
      mutateReadNotification(data.id);
    }
  };

  return (
    <div className="header-alert">
      <Alert
        message={
          <div
            className="header-alert__message"
            onClick={() => {
              onOpenModal(alertType);
            }}
          >
            {renderText(alertType, t)}
          </div>
        }
        showIcon={false}
        banner
        type={alertType}
        // @ts-ignore
        closeIcon={alertType !== EAlertType.Warning ? <CloseIcon /> : null}
        onClose={onCloseAlert}
      />
    </div>
  );
};
HeaderAlert.defaultProps = {
  alertType: EAlertType.Info,
  blockTime: null,
  data: null,
  isBanned: false,
};

export { HeaderAlert };
