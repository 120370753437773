export enum ECardSizeType {
  Small = 'small',
  Default = 'default',
  Minimal = 'minimal',
}

export interface IPropsType {
  title?: string;
  description?: string;
  isHideImg?: boolean;
  size?: ECardSizeType;
  isError?: boolean;
}
