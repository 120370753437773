import React, { useEffect, useState } from 'react';
import { Button } from 'antd';

// locale
import { useTranslation } from 'react-i18next';

// store

//hooks
import {
  useMutationReadAllNotifications,
  useQueryFetchNotificationList,
} from '../../../../../hooks/api';

// types
import { EButtonSizeType } from 'types/units';
import { ECardSizeType } from 'components/features/Cards/EmptyCard/types';

// components
import { CloseIcon } from 'components/icons';
import { HeaderNotice } from './HeaderNotice';
import { EmptyCard } from 'components/features';

// styles
import './index.scss';
import { useWindowSize } from 'react-use';
import { MEDIA } from 'utils/constants';
import { ENotificationsType } from 'types/api';

//data

const HeaderNotices = ({ onClose, notice, onReadComplete }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const [readedNoticeIds, setReadedNoticeIds] = useState<any>([]);
  const [noticeData, setNoticeData] = useState<any>([]);

  const {
    data: readNotificationsData,
    isLoading: isLoadingReadNotificationsData,
    isSuccess: isSuccessReadNotificationsData,
    mutate: mutateReadNotifications,
  } = useMutationReadAllNotifications();
  const { refetch: refetchNotificationList } =
    useQueryFetchNotificationList(true);
  const onReadAllNotice = () => {
    mutateReadNotifications();
  };

  useEffect(() => {
    if (notice?.length > 0) {
      let filteredNotice = notice
        .filter(item => readedNoticeIds.indexOf(item.id) === -1)
        .filter(
          item =>
            item.type !== ENotificationsType.UserInfoMessage &&
            item.type !== ENotificationsType.UserBlockMessage,
        );
      setNoticeData(filteredNotice);
    } else {
      setNoticeData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notice]);

  useEffect(() => {
    if (isSuccessReadNotificationsData) {
      onReadComplete([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessReadNotificationsData]);

  useEffect(() => {
    if (!isLoadingReadNotificationsData && readNotificationsData) {
      refetchNotificationList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readNotificationsData, isLoadingReadNotificationsData]);

  const onClosedNotice = id => {
    let newReadedNoticeIds = [...readedNoticeIds, id];
    let filteredNotice = noticeData.filter(
      item => newReadedNoticeIds.indexOf(item.id) === -1,
    );
    setReadedNoticeIds(newReadedNoticeIds);
    setNoticeData(filteredNotice);
    onReadComplete(filteredNotice);
  };

  return (
    <div className="header-notices">
      <div className="header-notices__head">
        <div className="header-notices__title">{t('notifications')}</div>
        <Button
          size={EButtonSizeType.Small}
          className="ant-btn-default--light header-notices__btn-read"
          onClick={() => onReadAllNotice()}
          disabled={!notice.length || isLoadingReadNotificationsData}
        >
          {t('readAll')}
        </Button>
        <CloseIcon
          className="header-notices__btn-close"
          onClick={() => onClose(false)}
        />
      </div>
      {noticeData?.length > 0 ? (
        <div
          className={
            'header-notices__list' +
            (noticeData.length > 3 && width > MEDIA.M1024
              ? ' header-notices__list--scroll'
              : '')
          }
        >
          <div className="header-notices__scroll">
            {noticeData.map((item, index) => (
              <div className="header-notices__item" key={item.date}>
                <HeaderNotice data={item} onClosed={onClosedNotice} />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <EmptyCard
          size={ECardSizeType.Small}
          title={t('emptyNotificationsTitle')}
          description={t('emptyNotificationsDesc')}
        />
      )}
    </div>
  );
};

export { HeaderNotices };
