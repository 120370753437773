import { Tooltip } from 'antd';
// core

// utils

// locales

//store

// types
import { IPropsType } from './types';

// components(atoms)
// styles
import './index.scss';
import { EGameType } from 'types/models';
import { StickerTooltip } from 'components/features';

// data

// func

const StickersBox = ({
  collection,
  sku,
  gameType,
  containerClassName,
  itemClassName,
}: IPropsType) => {
  return (
    <div
      className={
        'stickers-box ' + (containerClassName ? containerClassName : '')
      }
    >
      {collection.map((item, index) => (
        <Tooltip
          key={index}
          overlayClassName={'ant-tooltip--small'}
          placement="top"
          title={
            gameType === EGameType.CSGO ? (
              <StickerTooltip
                small
                title={item.title}
                price={item.valueExtra}
              />
            ) : (
              <StickerTooltip
                small
                title={item.title}
                description={item.valueExtra}
              />
            )
          }
        >
          <img
            src={item.image}
            key={`${sku}__${item.title.replace(/\s+/g, '')}`}
            alt={item.title}
            className={
              'stickers-box__sticker ' + (itemClassName ? itemClassName : '')
            }
          />
        </Tooltip>
      ))}
    </div>
  );
};

export { StickersBox };
