export const sortList: Array<{
  label: string;
  key: string;
  value: string;
}> = [
  {
    label: 'filters.featured',
    key: 'featured',
    value: '0',
  },
  {
    label: 'filters.qualityDesc',
    key: 'quality',
    value: 'DESC',
  },
  {
    label: 'filters.qualityAsc',
    key: 'quality',
    value: 'ASC',
  },
  {
    label: 'filters.priceDesc',
    key: 'price',
    value: 'DESC',
  },
  {
    label: 'filters.priceAsc',
    key: 'price',
    value: 'ASC',
  },
];
