export const sortQuality: Array<{
  label: string;
  value: string;
}> = [
  {
    label: 'filters.qualityDesc',
    value: 'DESC',
  },
  {
    label: 'filters.qualityAsc',
    value: 'ASC',
  },
];

export const sortPrice: Array<{
  label: string;
  value: string;
}> = [
  {
    label: 'filters.priceDesc',
    value: 'DESC',
  },
  {
    label: 'filters.priceAsc',
    value: 'ASC',
  },
];
