// core
import React, { useEffect, useState } from 'react';

//hooks
import { useQueryFetchRandomProducts } from '../../../../hooks/api';

// utils

// locales

// types

// components(atoms)
import { HomeMarqueeCard } from '../HomeMarqueeCard';

// styles
import './index.scss';
import { useSocketSubscribe } from '../../../../hoc/SocketProvider';
import { ESocketEventType } from 'types/api';

//tempo

// func
const HomeMarquee = ({ onLoaded }) => {
  const [products, setProducts] = useState<any>([]);

  const { data: randomProductsData, isLoading: isLoadingRandomProducts } =
    useQueryFetchRandomProducts();

  useEffect(() => {
    if (!isLoadingRandomProducts && randomProductsData) {
      setProducts(randomProductsData);
      onLoaded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randomProductsData, isLoadingRandomProducts]);

  const onSocketHandler = data => {
    if (products?.length && data) {
      setProducts([data, ...products]);
    }
  };

  useSocketSubscribe(ESocketEventType.BuyMarketItem, onSocketHandler);

  return (
    <>
      <div className="home-marquee">
        {!isLoadingRandomProducts && (
          <div className="home-marquee__wrap">
            {products?.length ? (
              products.map((item, index) => {
                return (
                  <div className="home-marquee__item" key={index}>
                    <HomeMarqueeCard
                      item={item}
                      index={index}
                      color={item.tag.rarity.valueExtra}
                    />
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export { HomeMarquee };
