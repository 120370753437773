import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { formatPrice } from '../../../../../utils/commons';
// types

// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore, useAuthStore } from 'store';
//hooks
import { useMutationFetchProductTradesDetails } from '../../../../../hooks/api';
// types

// components

// styles
import './index.scss';

//data

const ProductRequestCard = ({ request, name, onLoaded }) => {
  const { t } = useTranslation();
  const { accessToken: isAuth } = useAuthStore();
  const { sModalOpen } = useAppStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    data: requestDetails,
    isLoading: isLoadingRequestDetails,
    mutate: mutateRequestDetails,
  } = useMutationFetchProductTradesDetails();

  useEffect(() => {
    if (!isLoadingRequestDetails && requestDetails) {
      setIsLoading(false);
      onLoaded({ request, requestDetails });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestDetails, isLoadingRequestDetails]);

  const onShowRequest = data => {
    if (isAuth) {
      setIsLoading(true);
      let payload = {
        isAverage: data.isAverage,
        price: data.price,
        page: 1,
        take: 10,
        nameSearch: name,
      };
      mutateRequestDetails(payload);
    } else {
      sModalOpen('steamAuth');
    }
  };

  return (
    <div
      className={
        'product-requests-card' +
        (isLoading ? ' product-requests-card--loading' : '')
      }
      onClick={() => onShowRequest(request)}
    >
      {isLoading && (
        <div className="product-requests-card__loader">
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        </div>
      )}
      <div className="product-requests-card__title">
        <span>
          {request.isAverage ? t('more') : ''} $ {formatPrice(request.price)}
        </span>
      </div>
      <div className="product-requests-card__text">
        {request.tradeCount} {t('productRequests')}
      </div>
    </div>
  );
};

export { ProductRequestCard };
