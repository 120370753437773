// core

// utils
// locales
// store
// types
import { ELoaderThemeType, ELoaderTypeType, IPropsType } from './types';

// components(atoms)
// styles
import './index.scss';

// data

// func
function AppLoader({ type, theme, size, className }: IPropsType) {
  return (
    <div
      className={'app-loader ' + (className ? className : '')}
      style={{
        minHeight: size! * (type === ELoaderTypeType.Component ? 1 : 1.2),
      }}
      data-position={type}
      data-theme={theme}
    >
      {(type === ELoaderTypeType.Global ||
        type === ELoaderTypeType.Absolute) && (
        <div className="app-loader__overlay" />
      )}
      <div className="app-loader__content">
        <svg
          width={size}
          viewBox="0 0 179 212"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M170.196 76.0144C169.216 74.7874 168.167 73.6168 167.054 72.5081C165.95 71.4067 164.784 70.3692 163.561 69.4007C147.357 56.5702 124.091 57.9116 109.445 72.5185L109.397 72.5651L109.385 72.5548L99.1087 82.8026L99.036 82.7301L55.8354 125.796C52.139 129.493 47.2078 131.703 41.982 132.007C36.7561 132.31 31.6012 130.684 27.5001 127.44C26.8534 126.928 26.2367 126.379 25.6529 125.796C25.068 125.213 24.5166 124.597 24.0014 123.951C17.8957 116.282 17.9044 105.263 23.9651 97.596L74.9454 46.7557V66.9837H94.3463V0L9.19845 85.0262C3.19659 91.305 0.0666896 101.195 0.000906257 110.452C-0.0609566 119.715 3.04601 128.721 8.80895 135.986C9.78679 137.212 10.8335 138.383 11.944 139.492C13.0478 140.594 14.2142 141.631 15.4375 142.599C31.641 155.43 54.9075 154.088 69.5529 139.481L69.6014 139.435L69.6118 139.445L79.8878 129.197L79.9623 129.27L123.17 86.2036C126.866 82.5071 131.797 80.2966 137.023 79.9935C142.249 79.6904 147.404 81.3159 151.505 84.56C152.152 85.0723 152.768 85.621 153.352 86.2036C153.936 86.7876 154.487 87.4037 155.002 88.0491C161.108 95.7177 161.101 106.737 155.038 114.404L104.06 165.244V145.016H84.6519V212L169.8 126.972C175.803 120.693 178.933 110.803 178.999 101.546C179.062 92.2847 175.957 83.2788 170.196 76.0144Z"
            fill="#8D5DD9"
          />
        </svg>
      </div>
    </div>
  );
}

AppLoader.defaultProps = {
  type: ELoaderTypeType.Relative,
  theme: ELoaderThemeType.Light,
  size: 120,
} as IPropsType;

export { AppLoader };
