import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// core
// utils
// locales
// store
// types
import { IPropsType } from './types';
// components(atoms)
import { Typography } from 'antd';
import { CopyIcon } from 'components/icons';
// styles
import './index.scss';

// data

// func
const TableCellName = ({
  productId,
  first,
  originalMarketName,
  second,
  isOverflow,
}: IPropsType) => {
  return (
    <RouterLink
      to={`/market/${productId}`}
      className={
        'table-cell-name' + (isOverflow ? ' table-cell-name--overflow' : '')
      }
    >
      {second ? (
        <>
          <div className="table-cell-name__text">{first}</div>
          <Typography.Paragraph
            className="table-cell-name__type"
            copyable={{
              icon: [
                <CopyIcon key="copy-icon" />,
                <CopyIcon key="copied-icon" />,
              ],
              text: originalMarketName,
            }}
          >
            <span>{second}</span>
          </Typography.Paragraph>
        </>
      ) : (
        <>
          <Typography.Paragraph
            className="table-cell-name__text"
            copyable={{
              icon: [
                <CopyIcon key="copy-icon" />,
                <CopyIcon key="copied-icon" />,
              ],
              text: originalMarketName,
            }}
          >
            <span>{first}</span>
          </Typography.Paragraph>
        </>
      )}
    </RouterLink>
  );
};

export { TableCellName };
