import React from 'react';
// core
// utils
// locales
// store
// types
import { IPropsType } from './types';
// components(atoms)

// styles
import './index.scss';

// data

// func
const TableCellRarity = ({ rarity }: IPropsType) => {
  if (!rarity) return null;
  const { title, valueExtra } = rarity;
  return (
    <div className="table-cell-rarity" style={{ color: '#' + valueExtra }}>
      {title}
    </div>
  );
};

export { TableCellRarity };
