import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Table } from 'antd';
import { formatDate, formatPrice } from '../../../../utils/commons';
// locale
import { useTranslation } from 'react-i18next';
//hooks
import {
  useMutationFetchOutcomeBargain,
  useMutationRejectBargain,
} from '../../../../hooks/api';
// store
import { useAppStore } from 'store/app';
// types
import { EButtonSizeType, EButtonTypeType } from 'types/units';
import { ECardSizeType } from 'components/features/Cards/EmptyCard/types';

// components
import { ModalTeleport } from 'components/atoms';
import {
  EmptyCard,
  RemoveTradeModal,
  TableCellFloat,
  TableCellImage,
  TableCellStickers,
} from 'components/features';
import {
  ArrowLeftLightIcon,
  ArrowRightIcon,
  TrashIcon,
} from 'components/icons';
import { DealsHead } from '../DealsHead';
// styles
import './index.scss';

//data
import { EGameType } from 'types/models';
import { TableCellName } from '../../../../components/features/TableCells/TableCellName';
import { TableCellRarity } from '../../../../components/features/TableCells/TableCellRarity';

//data

const prepareTableData = array => {
  return array.map(el => {
    let { price, item, createdAt } = el;
    let {
      image,
      preview,
      name,
      shortenExterior,
      float,
      stickers,
      keychains,
      price: oldPrice,
      sku: id,
      provider,
      gems,
      tag,
      marketName,
      originalMarketName,
    } = item;
    return {
      id,
      date: createdAt ? formatDate(createdAt, 'DD.MM.YYYY') : '',
      img: image ? image : preview,
      name,
      shortenExterior,
      float,
      rarity: tag ? tag.rarity : null,
      stickers,
      keychains,
      gems,
      old_price: oldPrice,
      new_price: price,
      provider,
      marketName,
      originalMarketName,
    };
  });
};
const DealsTrade = () => {
  const { t } = useTranslation();
  const { sModalOpen } = useAppStore();
  const [tableData, setTableData] = useState<any>([]);
  const [rejectId, setRejectId] = useState<any>({
    sku: '',
  });
  const [filterData, setFilterData] = useState<any>({
    search: '',
    quality: 'DESC',
    price: 'DESC',
    page: 1,
    take: 10,
  });
  const {
    data: outcomeList,
    isLoading: isLoadingOutcomeList,
    mutate: mutateOutcomeList,
  } = useMutationFetchOutcomeBargain();

  const {
    data: rejectData,
    isLoading: isLoadingReject,
    mutate: mutateRejectBargain,
  } = useMutationRejectBargain();

  useEffect(() => {
    mutateOutcomeList({
      page: filterData.page,
      take: filterData.take,
      nameSearch: filterData.search,
      sort: {
        quality: filterData.quality,
        price: filterData.price,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    if (outcomeList && !isLoadingOutcomeList) {
      setTableData(prepareTableData(outcomeList?.data));
    }
  }, [isLoadingOutcomeList, outcomeList]);

  useEffect(() => {
    if (rejectData && !isLoadingReject) {
      mutateOutcomeList({
        // provider: gameType,
        page: filterData.page,
        take: filterData.take,
        nameSearch: filterData.search,
        sort: {
          quality: filterData.quality,
          price: filterData.price,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectData, isLoadingReject]);
  const onRemoveTradeDeal = useCallback(
    data => {
      setRejectId({
        sku: data.id,
      });
      sModalOpen('removeTrade');
    },
    [sModalOpen],
  );

  const onRejectOffer = () => {
    mutateRejectBargain(rejectId);
  };

  const onTableChange = pagination => {
    setFilterData({ ...filterData, page: pagination.current });
  };

  const dynamicColumns = useMemo(() => {
    return [
      {
        key: 'date',
        dataIndex: 'date',
      },
      {
        key: 'img',
        dataIndex: 'img',
        render: (_, record) => (
          <TableCellImage
            productId={record.id}
            src={record.img}
            gameType={record.provider}
            alt={''}
          />
        ),
      },
      {
        key: 'name',
        dataIndex: 'name',
        render: (_, record) => (
          <TableCellName
            first={record.marketName.first}
            second={record.marketName.second}
            originalMarketName={record.originalMarketName}
            productId={record.id}
          />
        ),
      },
      {
        key: 'float',
        dataIndex: 'float',
        render: (_, record) => (
          <>
            {record.provider === EGameType.CSGO ? (
              <TableCellFloat
                float={record.float}
                shortenExterior={record.shortenExterior}
              />
            ) : (
              <TableCellRarity rarity={record.rarity} />
            )}
          </>
        ),
      },
      {
        key: 'stickers',
        dataIndex: 'stickers',
        width: '45%',
        render: (_, record) => (
          <TableCellStickers
            gameType={record.provider}
            collection={
              record.provider === EGameType.CSGO
                ? [...record.stickers, ...record.keychains]
                : record.gems
            }
          />
        ),
      },
      {
        key: 'old_price',
        dataIndex: 'old_price',
        render: price => (
          <div className="ant-table-cell__nowrap">
            {t('tradeOldPrice')}:{' '}
            <span className="ant-table-cell__value">
              $ {formatPrice(price)}
            </span>
          </div>
        ),
      },
      {
        key: 'new_price',
        dataIndex: 'new_price',
        render: price => (
          <div className="ant-table-cell__nowrap">
            {t('tradeNewPrice')}:{' '}
            <span className="ant-table-cell__value">
              $ {formatPrice(price)}
            </span>
          </div>
        ),
      },
      {
        key: 'action',
        render: (_, record) => (
          <Button
            size={EButtonSizeType.Middle}
            icon={<TrashIcon />}
            type={EButtonTypeType.Text}
            className="ant-btn-text--error"
            onClick={() => onRemoveTradeDeal(record)}
          />
        ),
      },
    ];
  }, [t, onRemoveTradeDeal]);

  return (
    <>
      <DealsHead
        onCreateRequest={() => {}}
        enabled={true}
        filterData={filterData}
        onChangeQuality={value => {
          setFilterData({ ...filterData, quality: value });
        }}
        onChangePrice={value => {
          setFilterData({ ...filterData, price: value });
        }}
        onChangeSearch={value => {
          setFilterData({ ...filterData, search: value });
        }}
        createRequest={false}
      />
      <div className="deals-trade">
        <div className="app-container">
          <Table
            showHeader={false}
            onChange={pagination => {
              onTableChange(pagination);
            }}
            pagination={
              isLoadingOutcomeList
                ? false
                : {
                    position: ['bottomCenter'],
                    current: outcomeList?.meta?.page,
                    pageSize: outcomeList?.meta?.take,
                    total: outcomeList?.meta?.itemsCount,
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                    itemRender: (page, type, originalElement) => {
                      if (type === 'next') {
                        return <ArrowRightIcon />;
                      } else if (type === 'prev') {
                        return <ArrowLeftLightIcon />;
                      }
                      return originalElement;
                    },
                  }
            }
            loading={isLoadingOutcomeList}
            className="deals-trade__table"
            columns={dynamicColumns}
            dataSource={tableData}
            rowKey="id"
            scroll={{ x: '850px' }}
            locale={{
              emptyText: (
                <div className="ant-table-cell__table-empty">
                  <EmptyCard
                    size={ECardSizeType.Small}
                    title={t('tradesEmptyTitle')}
                    description={t('tradesEmptyDesc')}
                  />
                </div>
              ),
            }}
          />
          <ModalTeleport
            centered
            modalId={'removeTrade'}
            width={453}
            modalClass={'remove-save-modal__wrapper'}
          >
            <RemoveTradeModal
              onRemove={onRejectOffer}
              income={true}
              modalId={'removeTrade'}
            />
          </ModalTeleport>
        </div>
      </div>
    </>
  );
};

export { DealsTrade };
