import React, { useMemo } from 'react';
// core
// utils
// locales
// store
// types
import { IPropsType } from './types';
// components(atoms)

// styles
import './index.scss';
import { useTranslation } from 'react-i18next';
import { EDealStatusType } from 'types/models';

// data
const dataStatusKeys = {
  [EDealStatusType.Success]: {
    tKey: 'historyStatusDone',
    colorKey: 'success',
  },
  [EDealStatusType.Processing]: {
    tKey: 'historyStatusPending',
    colorKey: 'processing ',
  },
  [EDealStatusType.Rejected]: {
    tKey: 'historyStatusDeclined',
    colorKey: 'rejected',
  },
};

// func
const TableCellStatus = ({ status }: IPropsType) => {
  const { t } = useTranslation();
  const texStatus = useMemo(() => {
    return dataStatusKeys[status] ? (
      <span
        className={`table-cell-status__flag table-cell-status__flag--${dataStatusKeys[status].colorKey}`}
      >
        {t(dataStatusKeys[status].tKey)}
      </span>
    ) : (
      <span className={`table-cell-status__flag`}>
        {t('historyStatusUnknown')}
      </span>
    );
  }, [status, t]);
  return (
    <div className="table-cell-status">
      {t('status')}: {texStatus}
    </div>
  );
};

export { TableCellStatus };
