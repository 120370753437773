import React, { useEffect, useRef, useState } from 'react';
import { Button, Avatar, Typography, Input, Form } from 'antd';

// utils
import { formatPartsPrice, formatPrice } from '../../../../../utils/commons';

// locales
import { useTranslation } from 'react-i18next';
//hooks
import {
  useQueryFetchProfileInfo,
  useMutationSendProfileInfo,
  useQueryFetchUserBalance,
} from '../../../../../hooks/api';

// store
import { useAppStore } from 'store';

// types
import { EButtonSizeType, EAvatarSizeType } from 'types/units';
import { ELoaderTypeType } from '../../../../../components/atoms/AppLoader/types';

// components(atoms)
import {
  SteamIcon,
  CopyIcon,
  ArrowLightDownIcon,
  PlusIcon,
  MessageIcon,
  PersonOutlineIcon,
} from 'components/icons';
import { AppLoader } from 'components/atoms';
import { DashboardBlock, DashboardHead } from '../../components';

// styles
import './index.scss';

// data
import { toast } from 'react-toastify';
import * as yup from 'yup';

// func
const DashboardProfile = () => {
  const { t } = useTranslation();
  const [formName] = Form.useForm();
  const [formEmail] = Form.useForm();
  const { Paragraph } = Typography;
  const { sModalOpen } = useAppStore();
  const [enabledUserName, setEnabledUserName] = useState(false);
  const [enabledEmail, setEnabledEmail] = useState(false);
  const [enabledFetchProfile, setEnabledFetchProfile] = useState(false);
  const [enabledFetchUserBalance, setEnabledFetchUserBalance] = useState(false);

  const inputRefProfile = useRef<any>(null);
  const inputRefEmail = useRef<any>(null);

  useEffect(() => {
    if (enabledUserName) {
      inputRefProfile!.current!.focus();
    }
  }, [enabledUserName]);

  useEffect(() => {
    if (enabledEmail) {
      inputRefEmail!.current!.focus();
    }
  }, [enabledEmail]);

  const createProfileSchema = yup.object().shape({
    username: yup
      .string()
      .required(t('validations.required'))
      .max(70, t('validations.maxLength') + ' 70'),
  });

  const createEmailSchema = yup.object().shape({
    email: yup
      .string()
      .required(t('validations.required'))
      .email(t('validations.email'))
      .max(70, t('validations.maxLength') + ' 70'),
  });

  const yupSyncProfile = {
    async validator({ field }, value) {
      await createProfileSchema.validateSyncAt(field, { [field]: value });
    },
  };
  const yupSyncEmail = {
    async validator({ field }, value) {
      await createEmailSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const { data: userBalance, isLoading: isLoadingUserBalance } =
    useQueryFetchUserBalance(enabledFetchUserBalance);
  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    refetch: refetchProfileData,
  } = useQueryFetchProfileInfo(enabledFetchProfile);
  const {
    data: updateProfileData,
    isLoading: isLoadingUpdateProfile,
    mutate: mutateUpdateProfile,
  } = useMutationSendProfileInfo();

  useEffect(() => {
    if (profileData) {
      formName.setFieldsValue({
        username: profileData.username,
      });
      formEmail.setFieldsValue({
        email: profileData.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  useEffect(() => {
    if (updateProfileData && !isLoadingUpdateProfile) {
      setEnabledEmail(false);
      setEnabledUserName(false);
      toast.success(t('toastProfileUpdated'));
      setEnabledFetchProfile(true);
      refetchProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfileData, isLoadingUpdateProfile]);

  const saveUserName = () => {
    formName.submit();
  };
  const onSubmitUserName = payload => {
    mutateUpdateProfile(payload);
    setEnabledUserName(!enabledUserName);
  };

  const saveEmail = () => {
    formEmail.submit();
  };
  const onSubmitEmail = payload => {
    mutateUpdateProfile(payload);
    setEnabledEmail(!enabledEmail);
  };
  const onSubmitUserProfile = payload => {
    mutateUpdateProfile(payload);
  };
  const onAddBalance = () => {
    sModalOpen('addBalanceForm');
  };
  const onWithdrawBalance = () => {
    sModalOpen('withdrawForm');
  };
  const handleCopy = () => {
    // Use the copyable functionality provided by Ant Design
  };
  return (
    <div className="dashboard__main">
      <div className="dashboard__main--wrap">
        {isLoadingProfileData ? (
          <div className="dashboard__main-loader">
            <AppLoader type={ELoaderTypeType.Component} />
          </div>
        ) : (
          <>
            {profileData && (
              <>
                <DashboardHead title={t('profile')}>
                  <></>
                </DashboardHead>
                <div className="dashboard__main--user">
                  <div className="dashboard__main--user-data">
                    <div className="dashboard__main--user-data-avatar">
                      <Avatar
                        className="header-profile__avatar"
                        size={EAvatarSizeType.Large}
                        src={profileData.avatar?.large}
                      >
                        {profileData.username.charAt(0)}
                      </Avatar>
                      <h3>{profileData.username}</h3>
                    </div>
                    <div className="dashboard__main--user-data-status">
                      <SteamIcon />
                      <span>{t('connected')}</span>
                    </div>
                  </div>
                  <div className="dashboard__main--user-id">
                    <Paragraph
                      copyable={{
                        icon: [
                          <CopyIcon key="copy-icon" />,
                          <CopyIcon key="copied-icon" />,
                        ],
                        text: profileData?.uuid,
                      }}
                    >
                      ID {profileData?.uuid}
                    </Paragraph>
                  </div>
                </div>
                {userBalance && !isLoadingUserBalance && (
                  <DashboardBlock
                    title={t('balance')}
                    description={t('profileBalanceDesc')}
                    gradient
                  >
                    <div className="dashboard__main--balance-container">
                      <div className="dashboard__main--balance">
                        <div className="dashboard__main--balance-value">
                          <h3>
                            ${' '}
                            {
                              formatPartsPrice(userBalance.amount)
                                .significantDigit
                            }
                            <span>
                              .
                              {
                                formatPartsPrice(userBalance.amount)
                                  .fractionDigit
                              }
                            </span>
                          </h3>
                          <div className="dashboard__main--freeze">
                            $ <span>{formatPrice(userBalance?.reserved)}</span>{' '}
                            {t('freezed')}
                          </div>
                        </div>
                        <div className="dashboard__main--balance-controls">
                          <div className="dashboard__main--balance-controls-button">
                            <Button
                              onClick={() => onAddBalance()}
                              size={EButtonSizeType.Middle}
                              className="header-notice__btn"
                              type="primary"
                            >
                              <PlusIcon />
                            </Button>
                          </div>
                          <div className="dashboard__main--balance-controls-button">
                            <Button
                              onClick={() => onWithdrawBalance()}
                              size={EButtonSizeType.Middle}
                              className="ant-btn-primary--success header-notice__btn"
                              type="primary"
                            >
                              <ArrowLightDownIcon />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DashboardBlock>
                )}
                <DashboardBlock
                  title={t('profileSettingsTitle')}
                  description={t('profileSettingsDesc')}
                >
                  <div className="dashboard__main-form">
                    <Form
                      onFinish={onSubmitUserName}
                      form={formName}
                      layout="vertical"
                    >
                      <div className="dashboard__main-form-input dashboard__main-form-input--profile">
                        <Form.Item
                          name={'username'}
                          /*@ts-ignore*/
                          rules={[yupSyncProfile]}
                        >
                          <Input
                            ref={inputRefProfile}
                            className="ant-input-group-wrapper--custom-addon"
                            disabled={!enabledUserName}
                            addonBefore={
                              <>
                                <PersonOutlineIcon />
                                <span>{t('name')}</span>
                              </>
                            }
                            addonAfter={
                              enabledUserName ? (
                                <Button
                                  size={EButtonSizeType.Middle}
                                  className="ant-btn-text--primary"
                                  type="text"
                                  onClick={() => {
                                    saveUserName();
                                  }}
                                >
                                  {t('save')}
                                </Button>
                              ) : (
                                <Button
                                  size={EButtonSizeType.Middle}
                                  className="ant-btn-text--primary"
                                  type="text"
                                  onClick={() => {
                                    setEnabledUserName(!enabledUserName);
                                  }}
                                >
                                  {t('change')}
                                </Button>
                              )
                            }
                          />
                        </Form.Item>
                      </div>
                    </Form>
                    <Form
                      onFinish={onSubmitEmail}
                      form={formEmail}
                      layout="vertical"
                    >
                      <div className="dashboard__main-form-input dashboard__main-form-input--profile">
                        <Form.Item
                          name={'email'}
                          /*@ts-ignore*/
                          rules={[yupSyncEmail]}
                        >
                          <Input
                            ref={inputRefEmail}
                            className="ant-input-group-wrapper--custom-addon"
                            disabled={!enabledEmail}
                            addonBefore={
                              <>
                                <MessageIcon />
                                <span>{t('email')}</span>
                              </>
                            }
                            addonAfter={
                              enabledEmail ? (
                                <Button
                                  size={EButtonSizeType.Middle}
                                  className="ant-btn-text--primary"
                                  type="text"
                                  onClick={() => {
                                    saveEmail();
                                  }}
                                >
                                  {t('save')}
                                </Button>
                              ) : (
                                <Button
                                  size={EButtonSizeType.Middle}
                                  className="ant-btn-text--primary"
                                  type="text"
                                  onClick={() => {
                                    setEnabledEmail(!enabledEmail);
                                  }}
                                >
                                  {t('change')}
                                </Button>
                              )
                            }
                          />
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </DashboardBlock>
                {/*<DashboardBlock*/}
                {/*  title={t('profilePropositionsTitle')}*/}
                {/*  description={t('profilePropositionsDesc')}*/}
                {/*>*/}
                {/*  <div className="dashboard__main-form">*/}
                {/*    <div className="dashboard__main-form-switch">*/}
                {/*      <Switch />*/}
                {/*      <span>{t('iWantToGetEmails')}</span>*/}
                {/*    </div>*/}
                {/*    <div className="dashboard__main-form-switch">*/}
                {/*      <Switch />*/}
                {/*      <span>{t('iAgreeToImprove')}</span>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</DashboardBlock>*/}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export { DashboardProfile };
