// core
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
//hooks
import {
  useQueryFetchChatMessages,
  useQueryFetchProfileInfo,
  useQueryFetchTicket,
} from '../../../../hooks/api';
import {
  useSocketDispatch,
  useSocketSubscribe,
} from '../../../../hoc/SocketProvider';

// utils
//store
import { useAppStore } from 'store';

// locales
import { useTranslation } from 'react-i18next';
// types
import { EButtonSizeType, EButtonTypeType } from 'types/units';
import { ELoaderTypeType } from '../../../../components/atoms/AppLoader/types';
import { ESupportTicketStatusType } from 'types/models';
import { ESocketEmitType, ESocketEventType } from 'types/api';

// components(atoms)
import { SupportChatSEO } from './SEO';
import { ArrowLeftIcon } from 'components/icons';
import { Button } from 'antd';
import { MobileBottomNav } from 'components/features';
import { AppLoader, ModalTeleport } from 'components/atoms';
import {
  SupportModalAsk,
  SupportModalDone,
  SupportModalHelp,
} from 'containers/static';
import { SupportChatView } from './SupportChatView';
import { SupportChatInput } from './SupportChatInput';

// styles
import './index.scss';

const SupportTicketsChat = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { sModalOpen } = useAppStore();
  const [enabledFetch, setEnabledFetch] = useState(false);
  const [chatId, setChatId] = useState<any>(null);
  const [chatMessagesList, setChatMessagesList] = useState<any>([]);
  const { data: profileData, isLoading: isLoadingProfileData } =
    useQueryFetchProfileInfo(false);
  const { data: chatInfo, isLoading: isLoadingChatInfo } = useQueryFetchTicket(
    true,
    id,
  );
  const { data: chatMessages, isLoading: isLoadingChatMessages } =
    useQueryFetchChatMessages(enabledFetch, chatId);

  const { isConnected, socketEmit } = useSocketDispatch();

  const onSocketHandler = data => {
    if (data) {
      setChatMessagesList([data, ...chatMessagesList]);
    }
  };

  useSocketSubscribe(ESocketEventType.Message, onSocketHandler);

  useEffect(() => {
    if (!isLoadingChatInfo && chatId && profileData && isConnected) {
      const payload = {
        userId: profileData.id.toString(),
        chatId: chatId.toString(),
      };
      socketEmit(ESocketEmitType.JoinChat, payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingChatInfo, chatId, profileData, isConnected]);

  useEffect(() => {
    if (!isLoadingChatInfo && chatInfo && profileData) {
      setChatId(chatInfo.chatId);
      setEnabledFetch(true);
    }
  }, [isLoadingChatInfo, chatInfo, profileData]);

  useEffect(() => {
    if (!isLoadingChatMessages && chatMessages) {
      if (chatMessages && chatMessages.data?.length) {
        setChatMessagesList(chatMessages.data);
      }
    }
  }, [chatMessages, isLoadingChatMessages]);

  const onDoneChat = () => {
    sModalOpen('SupportModalAsk');
  };
  return (
    <>
      <SupportChatSEO />
      <div className="ticket">
        <div className="app-container app-container--small">
          {isLoadingChatInfo ? (
            <AppLoader type={ELoaderTypeType.Global} />
          ) : (
            <>
              <div className="ticket__wrap">
                {chatInfo && (
                  <>
                    <div className="ticket__head">
                      <div className="ticket__head--back">
                        <RouterLink to="/support/tickets">
                          <Button
                            type={EButtonTypeType.Default}
                            className={'ant-btn-ghost--grey'}
                            ghost
                            size={EButtonSizeType.Middle}
                          >
                            <ArrowLeftIcon />
                            {t('back')}
                          </Button>
                        </RouterLink>
                      </div>
                      <div className="ticket__head--text">
                        <div className="ticket__head--text-title">
                          {t('chat')}
                        </div>
                        <div className="ticket__head--text-desc">
                          {t('theme')} «{chatInfo.subject}»
                        </div>
                      </div>
                      <div className="ticket__head--action">
                        {chatInfo.status ===
                          ESupportTicketStatusType.Progress && (
                          <>
                            <Button
                              type={EButtonTypeType.Primary}
                              size={EButtonSizeType.Middle}
                              ghost
                            >
                              {t('jivoChat')}
                            </Button>
                            <Button
                              className={'ant-btn-ghost--success'}
                              type={EButtonTypeType.Default}
                              size={EButtonSizeType.Middle}
                              ghost
                              onClick={() => {
                                onDoneChat();
                              }}
                            >
                              {t('problemDone')}
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                    {isLoadingChatMessages ? (
                      <div className="ticket__loader">
                        <AppLoader type={ELoaderTypeType.Component} />
                      </div>
                    ) : (
                      <>
                        {chatMessagesList && chatMessagesList?.length && (
                          <div className="ticket__content">
                            <div className="ticket__content--chat">
                              <SupportChatView messages={chatMessagesList} />
                            </div>
                            {chatInfo.status ===
                              ESupportTicketStatusType.Progress && (
                              <div className="ticket__content--input">
                                <MobileBottomNav
                                  breakPoint={1024}
                                  className={''}
                                >
                                  <SupportChatInput chatId={chatInfo.chatId} />
                                </MobileBottomNav>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
        {chatInfo && (
          <>
            <ModalTeleport
              // maskClosable={false}
              // closeIcon={false}
              modalId={'SupportModalAsk'}
              centered
              width={1560}
              modalClass={'ant-modal--support ant-modal--support--closable'}
            >
              <SupportModalAsk id={id} ticket={chatInfo} />
            </ModalTeleport>
            <ModalTeleport
              centered
              modalId={'SupportModalHelp'}
              width={1560}
              modalClass={'ant-modal--support ant-modal--support--closable'}
            >
              <SupportModalHelp />
            </ModalTeleport>
            <ModalTeleport
              centered
              modalId={'SupportModalDone'}
              width={1560}
              modalClass={'ant-modal--support ant-modal--support--closable'}
            >
              <SupportModalDone />
            </ModalTeleport>
          </>
        )}
      </div>
    </>
  );
};

export { SupportTicketsChat };
