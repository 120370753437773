import React, { useEffect, useState } from 'react';
import IconRenderer from 'hoc/IconRenderer';
// utils

//hooks
import {
  useQueryFetchUserBalance,
  useQueryFetchWithdrawMethods,
} from '../../../../hooks/api';

//locales
import { useTranslation } from 'react-i18next';

// store
import { useAppStore } from 'store/app';

// types
import {
  EInputSizeType,
  EButtonSizeType,
  EButtonTypeType,
  ESelectSizeType,
} from 'types/units';
import { EPaymentSystemType } from 'types/models';

// components
import { Button, Input, Form } from 'antd';

import {
  ArrowDownIcon,
  MasterCardIcon,
  VisaCardIcon,
  PayPalIcon,
  BitcoinIcon,
  EtheriumIcon,
} from 'components/icons';

// styles
import './index.scss';
import { formatPartsPrice, formatPrice } from '../../../../utils/commons';
import * as yup from 'yup';
import { WithdrawBalanceConfirmation } from '../WithdrawBalanceConfirmation';
import { ModalTeleport } from 'components/atoms';
const prepareWithdrawMethods = array => {
  return array.map((method, index) => {
    let label = method.key;
    let icon: any = null;
    if (method.key === EPaymentSystemType.MasterCard) {
      label = 'MasterCard';
      icon = MasterCardIcon;
    }
    if (method.key === EPaymentSystemType.Visa) {
      label = 'Visa card';
      icon = VisaCardIcon;
    }
    if (method.key === EPaymentSystemType.PayPal) {
      label = 'PayPal';
      icon = PayPalIcon;
    }
    if (method.key === EPaymentSystemType.Bitcoin) {
      label = 'Bitcoin';
      icon = BitcoinIcon;
    }
    if (method.key === EPaymentSystemType.Etherium) {
      label = 'Etherium';
      icon = EtheriumIcon;
    }
    return {
      tax: method.commission,
      id: index,
      label: label,
      icon: icon,
      key: method.key,
    };
  });
};
// data
const WithdrawBalanceForm = ({ modalId }) => {
  const { t } = useTranslation();
  const { sModalOpen, sModalClose } = useAppStore();
  const [form] = Form.useForm();

  const [methodList, setMethodList] = useState<any>([]);
  const [methodForm, setMethodForm] = useState<any>([]);

  const [withdrawAmount, setWithdrawAmount] = useState<number>(0);
  const [withdrawTaxAmount, setWithdrawTaxAmount] = useState<number>(0);

  const [withdrawPayload, setWithdrawPayload] = useState<any>(null);

  const [withdrawSchema, setWithdrawSchema] = useState(
    yup.object().shape({
      amount: yup
        .number()
        .required(t('validations.required'))
        .typeError(t('validations.isNumeric')),
    }),
  );
  const yupSync = {
    async validator({ field }, value) {
      await withdrawSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const [currentCard, setCurrentCard] = useState<{
    [key: string]: any;
  } | null>(null);

  const { data: userBalance, isLoading: isLoadingUserBalance } =
    useQueryFetchUserBalance(false);

  const { data: withdrawMethods, isLoading: isLoadingWithdrawMethods } =
    useQueryFetchWithdrawMethods();

  useEffect(() => {
    if (!isLoadingWithdrawMethods && withdrawMethods?.length) {
      setMethodList(prepareWithdrawMethods(withdrawMethods));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawMethods, isLoadingWithdrawMethods]);

  useEffect(() => {
    setCurrentCard(methodList[0]);
    if (withdrawMethods && methodList) {
      setMethodForm(withdrawMethods[0]?.formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methodList, withdrawMethods]);

  useEffect(() => {
    if (methodForm?.length) {
      let additionalValidation = methodForm.reduce((acc, item) => {
        return {
          ...acc,
          [item.name]: yup.string().required(t('validations.required')),
        };
      }, {});
      setWithdrawSchema(
        yup.object().shape({
          amount: yup
            .number()
            .required(t('validations.required'))
            .typeError(t('validations.isNumeric')),
          ...additionalValidation,
        }),
      );
      setWithdrawTaxAmount(
        withdrawAmount && currentCard
          ? Number((withdrawAmount * currentCard?.tax) / 100)
          : 0,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methodForm]);

  const onConfirm = payload => {
    setWithdrawPayload({
      ...payload,
      tax: withdrawTaxAmount,
      commission: currentCard?.tax,
      service: currentCard?.key,
    });
    sModalOpen('withdrawConfirmation');
  };

  const onSubmitForm = () => {
    form.submit();
  };
  const onChooseCard = card => {
    if (currentCard?.key !== card.key) {
      setCurrentCard(card);
      setWithdrawSchema(
        yup.object().shape({
          amount: yup
            .number()
            .required(t('validations.required'))
            .typeError(t('validations.isNumeric')),
        }),
      );
      setMethodForm(withdrawMethods?.find(el => el.key === card.key)?.formData);
    }
  };

  const handleAmountChange = e => {
    const value = e.target.value.replace(/[^\d.]/g, '');
    setWithdrawAmount(Number(value));
    setWithdrawTaxAmount(
      value && currentCard ? Number((value * currentCard?.tax) / 100) : 0,
    );
  };
  const handleCardNumberChange = e => {
    const input = e.target.value.replace(/\D/g, '');
    let formattedInput = '';
    for (let i = 0; i < input.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedInput += ' ';
      }
      formattedInput += input[i];
    }
    if (formattedInput.length < 19) {
      form.setFieldsValue({ card: formattedInput });
    } else {
      form.setFieldsValue({ card: formattedInput.slice(0, 19) });
    }
  };

  return (
    <div className="withdraw-balance-form">
      <div className="withdraw-balance-form__grid">
        <div className="withdraw-balance-form__aside">
          <div className="withdraw-balance-form__main--title mobile">
            {t('decreaseFunds')}
          </div>
          <div className="withdraw-balance-form__aside--list">
            {methodList?.length &&
              methodList.map(card => {
                return (
                  <div
                    className={
                      'withdraw-balance-form__aside--list-item' +
                      (card.key === currentCard?.key
                        ? ' withdraw-balance-form__aside--list-item--active'
                        : '')
                    }
                    onClick={() => onChooseCard(card)}
                    key={card.id}
                  >
                    <div className="withdraw-balance-form__aside--list-item-label">
                      {card.icon
                        ? IconRenderer(
                            card.icon,
                            'withdraw-balance-form__aside--list-item-icon',
                          )
                        : null}
                      <span>{card.label}</span>
                    </div>
                    <div className="withdraw-balance-form__aside--list-item-value">
                      {card.tax}%
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="withdraw-balance-form__main">
          <div className="withdraw-balance-form__main--title desktop">
            {t('decreaseFunds')}
          </div>
          {!isLoadingUserBalance && (
            <div className="withdraw-balance-form__main--balance">
              {t('yourBalance')}: ${' '}
              <span>
                {formatPartsPrice(userBalance?.amount).significantDigit}
              </span>
              .{formatPartsPrice(userBalance?.amount).fractionDigit}
            </div>
          )}
          {methodForm?.length && (
            <Form onFinish={onConfirm} form={form} layout="vertical">
              <div className="withdraw-balance-form__main--form">
                {methodForm.map((item, index) => {
                  return (
                    <>
                      <div
                        className="withdraw-balance-form__main--form-item"
                        key={1111 + index}
                      >
                        {item.name !== 'card' ? (
                          <Form.Item
                            name={item.name}
                            /*@ts-ignore*/
                            rules={[yupSync]}
                            label={t(
                              `withdrawForm${
                                item.name.charAt(0).toUpperCase() +
                                item.name.slice(1)
                              }`,
                            )}
                          >
                            <Input
                              className={'ant-input--borderless'}
                              size={EInputSizeType.Large}
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            name={item.name}
                            /*@ts-ignore*/
                            rules={[yupSync]}
                            label={t(
                              `withdrawForm${
                                item.name.charAt(0).toUpperCase() +
                                item.name.slice(1)
                              }`,
                            )}
                          >
                            <Input
                              onChange={handleCardNumberChange}
                              className={'ant-input--borderless'}
                              size={EInputSizeType.Large}
                            />
                          </Form.Item>
                        )}
                      </div>
                    </>
                  );
                })}
                <div className="withdraw-balance-form__main--form-item">
                  <Form.Item
                    name={'amount'}
                    /*@ts-ignore*/
                    rules={[yupSync]}
                    label={t('withdrawFormAmount')}
                  >
                    <Input
                      onChange={handleAmountChange}
                      type={'number'}
                      className={'ant-input--borderless'}
                      size={EInputSizeType.Large}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
      <div className="withdraw-balance-form__footer">
        <div className="withdraw-balance-form__footer--value">
          <h3>
            {t('total')}: $ {formatPrice(withdrawAmount)}
          </h3>
          <span>
            {t('commission')}: $ {formatPrice(withdrawTaxAmount)}
          </span>
        </div>
        <div className="withdraw-balance-form__footer--actions">
          <Button
            type={EButtonTypeType.Primary}
            size={EButtonSizeType.Large}
            onClick={() => {
              onSubmitForm();
            }}
          >
            {t('decreaseFundsBtn')}
          </Button>
        </div>
      </div>
      <ModalTeleport centered modalId={'withdrawConfirmation'} width={785}>
        <WithdrawBalanceConfirmation
          formData={withdrawPayload}
          modalId={'withdrawConfirmation'}
        />
      </ModalTeleport>
    </div>
  );
};

export { WithdrawBalanceForm };
