import { $apiAuth } from 'utils/request';

import type { AxiosResponse } from 'axios';
import type { TLoginRequestParams, ILoginResponse } from 'types/api/auth';
import {
  IRefreshTokenResponse,
  TAuthLinkResponse,
  TRefreshTokenRequestParams,
} from 'types/api/auth';

export const GET_AUTH_LINK = async () => {
  const url = `steam-sign-in`;
  const response = await $apiAuth.get<TAuthLinkResponse>(url);
  return response.data;
};
export const POST_LOGIN = async (params: TLoginRequestParams) => {
  const url = `auth?token=${params}`;
  const response = await $apiAuth.get<ILoginResponse>(url);
  return response.data;
};

export const POST_LOGOUT = async () => {
  const response: AxiosResponse = await $apiAuth.get('log-out');
  return response;
};

export const POST_REFRESH_TOKEN = async (token: TRefreshTokenRequestParams) => {
  const response: AxiosResponse<IRefreshTokenResponse> =
    await $apiAuth.get<IRefreshTokenResponse>(
      `refresh-token?refreshToken=${token}`,
    );
  return response.data;
};
