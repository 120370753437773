import React from 'react';
// core
// utils
// locales
// store
// types
import { IPropsType } from './types';
// components(atoms)

// styles
import './index.scss';
import { FloatRange } from 'components/atoms';
import { EFloatRangeSizeType } from '../../../atoms/FloatRange/types';

// data

// func
const TableCellFloat = ({ float, shortenExterior }: IPropsType) => {
  return (
    <>
      {float > 0 && (
        <div className="table-cell-float">
          <div className="table-cell-float__float">
            {shortenExterior}
            <span>{float}</span>
          </div>
          <FloatRange
            isTooltip={!float}
            size={EFloatRangeSizeType.Small}
            maxValue={float}
          />
        </div>
      )}
    </>
  );
};

export { TableCellFloat };
