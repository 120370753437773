export const generalSort: Array<{
  label: string;
  value: string;
  data: Object;
}> = [
  {
    label: 'filters.featured',
    value: 'recommended',
    data: {
      sort: {
        createdAt: null,
        price: null,
        quality: null,
      },
    },
  },
  {
    label: 'filters.dateDesc',
    value: 'date_oldest',
    data: {
      sort: {
        createdAt: 'DESC',
      },
    },
  },
  {
    label: 'filters.dateAsc',
    value: 'date_newest',
    data: {
      sort: {
        createdAt: 'ASC',
      },
    },
  },
  {
    label: 'filters.priceDesc',
    value: 'price_highest',
    data: {
      sort: {
        price: 'DESC',
      },
    },
  },
  {
    label: 'filters.priceAsc',
    value: 'price_lowest',
    data: {
      sort: {
        price: 'ASC',
      },
    },
  },
  {
    label: 'filters.qualityDesc',
    value: 'quality_highest',
    data: {
      sort: {
        quality: 'DESC',
      },
    },
  },
  {
    label: 'filters.qualityAsc',
    value: 'quality_lowest',
    data: {
      sort: {
        quality: 'ASC',
      },
    },
  },
];

export const stickersSort: Array<{
  label: string;
  value: string;
  data: Object;
}> = [
  {
    label: 'filters.featured',
    value: 'sticker_recommended',
    data: {
      countSticker: null,
    },
  },
  {
    label: 'filters.stickers5',
    value: 'sticker_5_and_less',
    data: {
      countSticker: 5,
    },
  },
  {
    label: 'filters.stickers4',
    value: 'sticker_4_and_less',
    data: {
      countSticker: 4,
    },
  },
  {
    label: 'filters.stickers3',
    value: 'sticker_3_and_less',
    data: {
      countSticker: 3,
    },
  },
  {
    label: 'filters.stickers2',
    value: 'sticker_2_and_less',
    data: {
      countSticker: 2,
    },
  },
  {
    label: 'filters.stickers1',
    value: 'sticker_1',
    data: {
      countSticker: 1,
    },
  },
];

export const priceSort: Array<{
  label: string;
  value: string;
  data: Object;
}> = [
  {
    label: 'filters.price2000',
    value: '200_2000',
    data: {
      price: {
        from: 200,
        to: 2000,
      },
    },
  },
  {
    label: 'filters.price200',
    value: '100_200',
    data: {
      price: {
        from: 100,
        to: 200,
      },
    },
  },
  {
    label: 'filters.price100',
    value: '10_100',
    data: {
      price: {
        from: 10,
        to: 100,
      },
    },
  },
];
