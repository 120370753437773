import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
// locale

// store
import { useAppStore, useAuthStore } from 'store';

// types

// components

// styles
import './index.scss';

// data
import { HeaderNotices } from '../../components/HeaderNotices';
import {
  useQueryFetchNotificationList,
  useQueryFetchProfileInfo,
} from '../../../../../hooks/api';
import { useSocketSubscribe } from '../../../../../hoc/SocketProvider';
import { ESocketEventType } from 'types/api';

const HeaderNoticeDrawer = () => {
  const { accessToken: isAuth } = useAuthStore();

  const { drawer, headerTopOffset, sDrawerClose } = useAppStore();
  //
  const [enableFetchNotifications, setEnableFetchNotifications] =
    useState<boolean>(false);
  const [notifications, setNotifications] = useState<any>([]);

  const { data: notificationList } = useQueryFetchNotificationList(
    enableFetchNotifications,
  );

  const { data: profileData } = useQueryFetchProfileInfo(false);

  const onSocketHandler = data => {
    if (data) {
      setNotifications([data, ...notifications]);
    }
  };

  useSocketSubscribe(ESocketEventType.Notification, onSocketHandler);

  useEffect(() => {
    if (notificationList) {
      setNotifications(notificationList);
    }
  }, [notificationList]);

  useEffect(() => {
    if (isAuth && profileData) {
      setEnableFetchNotifications(true);
    }
  }, [isAuth, profileData]);

  const onClose = () => {
    sDrawerClose('headerNoticeDrawer');
  };

  const onReadComplete = list => {
    setNotifications(list);
  };

  return (
    <Drawer
      placement="left"
      destroyOnClose={true}
      closable={false}
      onClose={onClose}
      open={drawer['headerNoticeDrawer']}
      getContainer={false}
      rootClassName={'header-notice-drawer'}
      rootStyle={{ height: `calc(100vh - ${headerTopOffset}px)` }}
      width={320}
    >
      <HeaderNotices
        onClose={onClose}
        onReadComplete={onReadComplete}
        notice={notifications}
      />
    </Drawer>
  );
};

export { HeaderNoticeDrawer };
