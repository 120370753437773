import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { MEDIA } from '../../../../../utils/constants';
// locale

// store

// types
import { EButtonSizeType } from 'types/units';
import { ECardSizeType } from '../../../../../components/features/Cards/EmptyCard/types';

// components
import { EmptyCard, MobileBottomNav } from 'components/features';

// styles
import './index.scss';

//data
const InventoryEmpty = ({ isSteamUpdateProcessed, width, onSteamReload }) => {
  const { t } = useTranslation();
  return (
    <div className="inventory__empty">
      <EmptyCard
        size={ECardSizeType.Default}
        title={t('emptyInventoryTitle')}
        description={t('emptyInventoryDesc')}
      />
      <MobileBottomNav
        breakPoint={768}
        className="inventory-products__empty-actions"
      >
        <Button
          className="inventory-products__empty-btn-market"
          type="primary"
          disabled={isSteamUpdateProcessed}
          block={width <= MEDIA.M768}
          size={EButtonSizeType.Large}
          onClick={() => onSteamReload()}
        >
          {t('updateInventory')}
        </Button>
      </MobileBottomNav>
    </div>
  );
};

export { InventoryEmpty };
