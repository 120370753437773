import React from 'react';

// utils

// locales
import { useTranslation } from 'react-i18next';

// store
import { useAppStore } from 'store';

// types
import { EButtonSizeType } from 'types/units';

// components(atoms)
import { Button, Input, Form } from 'antd';
import { LockLineIcon } from 'components/icons';

// styles
import './index.scss';
import * as yup from 'yup';

// data

// func

const WithdrawBalancePassword = ({ modalId, onSubmitPassword }) => {
  const { t } = useTranslation();
  const { sModalClose } = useAppStore();
  const [form] = Form.useForm();
  const paymentPassSchema = yup.object().shape({
    password: yup
      .string()
      .required(t('validations.required'))
      .min(4, t('validations.minLength') + ' 4')
      .max(24, t('validations.maxLength') + ' 24'),
  });
  const yupSync = {
    async validator({ field }, value) {
      await paymentPassSchema.validateSyncAt(field, { [field]: value });
    },
  };
  const onConfirm = payload => {
    onSubmitPassword(payload);
    sModalClose(modalId);
  };

  return (
    <div className="dashboard__create-payment-password">
      <div className="dashboard__create-payment-password--icon">
        <LockLineIcon />
      </div>
      <div className="dashboard__create-payment-password--title">
        {t('paymentPassword')}
      </div>
      <div className="dashboard__create-payment-password--desc">
        {t('passToProceed')}
      </div>
      <Form onFinish={onConfirm} form={form} layout="vertical">
        <div className="dashboard__create-payment-password--input">
          <Form.Item
            name={'password'}
            /*@ts-ignore*/
            rules={[yupSync]}
          >
            <Input.Password
              addonBefore={t('paymentPassword')}
              placeholder="********"
            />
          </Form.Item>
        </div>
        <div className="dashboard__create-payment-password--button">
          <Button
            htmlType={'submit'}
            type="primary"
            block
            size={EButtonSizeType.Large}
          >
            {t('nextStep')}
          </Button>
        </div>
      </Form>
    </div>
  );
};
export { WithdrawBalancePassword };
